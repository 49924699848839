import React, { useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import MyCategurys from './components/MyPages/CategurysP/MyCategurys';
import HomePage from './components/MyPages/HomeP/HomePage';
import ManagmentPage from './components/MyPages/ManagmentP/ManagmentPage';
import MasterSettings from './components/MyPages/MasterP/MasterSettings';
import ReportsP from './components/MyPages/OrdersP/Orders-Reports/ReportsP';
import Orderslist from './components/MyPages/OrdersP/Orderslist';
import MyRestSettings from './components/MyPages/RestsP/MyRestSettings';
import MyRestaurantlist from './components/MyPages/RestsP/Restaurantlist';
import Preloader from './components/layouts/Preloader';

import ClientUsersListP from './components/MyPages/ClientUsers/ClientUsersListP';
import Login from './components/MyPages/Login/Login';
import WrapRegister from './components/MyPages/Login/Register/WrapRegister';
import UsersListP from './components/MyPages/UsersP/UsersListP';


import utilsContactPage from './MyConns/_Utils/utilsContactPage';
import utilsSinglePage from './MyConns/_Utils/utilsSinglePage';
import { BuisListContext } from './components/MyContexts/BuisList_Context';
import BlackListP from './components/MyPages/BlackList/BlackListP';
import ContactPage from './components/MyPages/ContactPage.js/ContactPage';
import KoponsWrap from './components/MyPages/KoponsPage/KoponsWrap';
import ResetPasswordPage from './components/MyPages/Login/ResetPasswordP/ResetPasswordPage';
import BuisUserprofile from './components/MyPages/Login/Userprofile/BuisUserprofile';
import ImageGall from './components/MyPages/MediaPage/ImageGall';
import MyInvoice from './components/MyPages/OrdersP/OrderItem/Invoice/Invoice';
import ProgramrsMsgs from './components/MyPages/ProgramrsMsgsPage/ProgramrsMsgs';
import SinglePage from './components/MyPages/SinglePage/SinglePage';
import TblOrderslist from './components/MyPages/TblOrdersP/TblOrderslist';
import ClientUserItem from './components/MyPages/ClientUsers/ClientUserItem';
import { UserContext } from './components/MyContexts/Users_Context';
import myFunctions from './myFunctions';
import ClientUserItemWrap from './components/MyPages/ClientUsers/ClientUserItemWrap';

const App = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [lstSimpePages, setLstSimpePages] = useState([]);
  const [lstContactPages, setLstContactPages] = useState([]);
  const [buisLst, setBuisLst] = useContext(BuisListContext);

  useEffect(() => {

    getSimplePages();
    getContactPages();
  }, [])

  const getSimplePages = async () => {
    let tempo = await utilsSinglePage.allPgsNames();
    setLstSimpePages(tempo)

  }

  const upsSimpleList = (newitm) => {

    let tempo = [...lstSimpePages]
    tempo.push(newitm);
    setLstSimpePages(tempo)
  }



  const getContactPages = async () => {
    let tempo = await utilsContactPage.allPgsNames();
    setLstContactPages(tempo)

  }

  const upContactList = (newitm) => {

    let tempo = [...lstContactPages]
    tempo.push(newitm);
    setLstContactPages(tempo)

  }


  return (
    <Router>
      <Preloader />
      <Switch>
        <Route path="/Master" component={MasterSettings} />
        <Route exact path="/Rest" component={MyRestSettings} />
        <Route path="/Rests" component={MyRestaurantlist} />
        <Route path="/HomeP" component={HomePage} />
        <Route path="/allCats" component={MyCategurys} />
        <Route path="/Manage" component={ManagmentPage} />
        <Route path="/ImageGall" component={ImageGall} />
        <Route path="/ProgramrsMsgs" component={ProgramrsMsgs} />
        <Route path="/Kopons" component={KoponsWrap} />
        <Route path="/Users" component={UsersListP} />
        <Route exact path="/ClientUsers" component={ClientUsersListP} />
        <Route path="/ClientUsers/:id" component={ClientUserItemWrap} />
        <Route path="/BlackLst" component={BlackListP} />
        <Route path="/ReportsP" component={ReportsP} />

        {lstSimpePages?.map((item, index) => {
          return (
            <Route key={`${index}_rout`} path={`/${item.inLinkToPage}`} >
              <SinglePage key={index} pageItem={item} upSimplsList={(newitm) => upsSimpleList(newitm)} />
            </Route>)
        })}

        <Route path="/nswSp">
          <SinglePage key={"np"} newp={true} pageItem={""} upSimplsList={(newitm) => upsSimpleList(newitm)} />
        </Route>

        {lstContactPages?.map((item, index) => {

          return (
            <Route key={`${index}_rout1`} path={`/${item.inLinkToPage}`} >
              <ContactPage key={index} pageItem={item} upContactList={(newitm) => upContactList(newitm)} />
            </Route>
          )
        })}

        <Route path="/nswContP">
          <ContactPage key={"nswSp"} newp={true} pageItem={""} upContactList={(newitm) => upContactList(newitm)} />
        </Route>



        {/* <Route exact path="/" component={MyDashboard} /> */}
        <Route exact path="/" component={Orderslist} />
        <Route path="/Orders" component={Orderslist} />
        <Route path="/Order/:id" component={MyInvoice} />

        <Route path="/Login" ><Login /></Route>
        <Route path="/Register"><WrapRegister /></Route>
        <Route path="/Rest/:id" component={MyRestSettings} />
        <Route path="/TblOrders" component={TblOrderslist} />

        {/* <Route path="/Order/:id" component={OrderItem} /> */}
        <Route path="/UsrPofile" component={BuisUserprofile} />
        <Route path="/resPass" component={ResetPasswordPage} />





        {/* <Route component={MyDashboard} /> */}

      </Switch>
    </Router>

  );
}

export default App;
