import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import logo from '../../assets/img/costic/costic-logo-216x62.png';
import { TheMasterContext } from '../MyContexts/Master_Context';
import ConnUrls from '../../MyConns/Connections/ConnectURL';
import { AddBoxOutlined, CountertopsOutlined } from '@mui/icons-material';
import utilsMaster from '../../MyConns/_Utils/utilsMaster';
import utilsSinglePage from '../../MyConns/_Utils/utilsSinglePage';
import { Button } from '@mui/material';
import myFunctions from '../../myFunctions';
import { UserContext } from '../MyContexts/Users_Context';
import utilsContactPage from '../../MyConns/_Utils/utilsContactPage';


const Sidenavigation = (props) => {

    const [user, setUser] = useContext(UserContext);
    const [theMaster, setTheMaster] = useContext(TheMasterContext);
    const [lstSimpePages, setLstSimpePages] = useState([]);
    const [lstContactpages, setLstContactpages] = useState([]);
    const [showSpagesList, setShowSpagesList] = useState(false);
    const [showContactsList, setShowContactsList] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        getCheck();
    })

    useEffect(() => {

        checkForMaster();

        getSimplePages();
        getConatctPages();
        setActiveMenuItem();


    }, [])


    const getCheck = async () => {
        let takeBak = await myFunctions.connCheck();
    }


    const checkForMaster = async () => {
        if (theMaster.length === 0) {
            let mast = await utilsMaster.getMaster();
            setTheMaster(mast)
        }
        setLoading(false)
    }
    const getSimplePages = async () => {
        let tempo = await utilsSinglePage.allPgsNames();
        setLstSimpePages(tempo)

    }
    const getConatctPages = async () => {
        let tempo = await utilsContactPage.allPgsNames();
        setLstContactpages(tempo)

    }


    useEffect(() => {

    }, [theMaster])

    const setActiveMenuItem = () => {

        $('.ms-main-aside .menu-item>a').on('click', function () {
            $(this).removeAttr('href');
            var element = $(this).parent('li');
            if (element.hasClass('active')) {
                element.removeClass('active');
                element.find('li').removeClass('active');
                element.find('.collapse').slideUp();
            } else {
                element.addClass('active');
                element.children('.collapse').slideDown();
                element.siblings('li').children('.collapse').slideUp();
                element.siblings('li').removeClass('active');
                element.siblings('li').find('li').removeClass('active');
                element.siblings('li').find('.collapse').slideUp();
            }
        });
    }

    const removeoverlay = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    }

    return (
        (!loading &&
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={() => removeoverlay()}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left">
                    {/* Logo */}
                    <div className="logo-sn ms-d-block-lg">
                        <Link className="pl-0 ml-0 text-center" to="/">
                            <img src={ConnUrls.servMediasUrl + theMaster.manageSiteLogo} alt="logo" />
                        </Link>
                    </div>
                    {/* Navigation */}

                    <ul className="accordion ms-main-aside fs-14 sideNavi" id="side-nav-accordion">

                        {
                            (user.permiss != "admin") ?
                                <>
                                    {/* Orders */}
                                    {(theMaster.ordersOn &&
                                    <li className={`menu-item ${window.location.href.includes('/Orders') ? "activeLnk" : ""}`} >
                                        <Link to="/Orders"> <span><i className="fa fa-credit-card fs-16" />הזמנות</span>
                                        </Link>
                                    </li>
                                    )}
                                    {(theMaster.tblsRsv &&
                                        <li className={`menu-item ${window.location.href.includes('/TblOrders') ? "activeLnk" : ""}`} >
                                            <Link to="/TblOrders"> <span><i className="fa fa-credit-card fs-16" />הזמנת שולחנות</span>
                                            </Link>
                                        </li>
                                    )}

                                    {user.buisID?.split(',').length == 1 ?
                                        <li className={`menu-item ${window.location.href.includes('/Rest/') ? "activeLnk" : ""}`} >
                                            <Link to={`/Rest/${user.buisID}`}> <span><i className="fa fa-coffee fs-16" />פרטי העסק</span>
                                            </Link>
                                        </li>
                                        :
                                        user.buisID?.split(',').map((buid, indx) => {
                                            let names = user.buisName.split(',');
                                            return (
                                                <li key={indx} className={`menu-item ${window.location.href.includes(`/Rest/${buid}`) ? "activeLnk" : ""}`} >
                                                    <a href={`/Rest/${buid}`}> <span><i className="fa fa-coffee fs-16" />{names[indx]}</span>
                                                    </a>
                                                </li>
                                            )
                                        }
                                        )
                                    }
                                </>
                                : ""
                        }

                        {/* Dashboard */}
                        {/* <li className={`menu-item ${(window.location.href.includes('/Dashboard') || window.location === '/') ? "activeLnk" : ""}`} >
                        <Link to="/"> <span><i className="fa fa-globe fs-16" />ראשי</span>
                        </Link>
                    </li> */}

                        {(user.permiss === "admin") ?
                            <>
                                <li className={`menu-item ${window.location.href.includes('/Orders') || window.location.href.endsWith("/") ? "activeLnk" : ""}`} >
                                    <Link to="/Orders"> <span><i className="fa fa-credit-card fs-16" />הזמנות</span>
                                    </Link>
                                </li>
                                {/* Tbales Orders */}
                                <li className={`menu-item ${window.location.href.includes('/TblOrders') ? "activeLnk" : ""}`} >
                                    <Link to="/TblOrders"> <span><i className="fa fa-credit-card fs-16" />הזמנת שולחנות</span>
                                    </Link>
                                </li>
                                {/* Master */}
                                < li className={`menu-item ${window.location.href.includes('/Master') ? "activeLnk" : ""}`} >
                                    <Link to="/Master"> <span><i className="fa fa-archive fs-16" />Master</span>
                                    </Link>
                                </li>
                                {/* HomePaage */}
                                <li className={`menu-item ${window.location.href.includes('/HomeP') ? "activeLnk" : ""}`} >
                                    <Link to="/HomeP"> <span><i className="fa fa-home fs-16" />עמוד הבית</span>
                                    </Link>
                                </li>
                                {/* Restaurants */}
                                <li className={`menu-item ${window.location.href.includes('/Rests') ? "activeLnk" : ""}`} >
                                    <Link to="/Rests"> <span><i className="fa fa-coffee fs-16" />עסקים</span>
                                    </Link>
                                </li>

                                {/* Categurys */}
                                <li className={`menu-item ${window.location.href.includes('/allCats') ? "activeLnk" : ""}`} >
                                    <Link to="/allCats"> <span><i className="fa fa-list fs-16" />קטגוריות</span>
                                    </Link>
                                </li>
                                {/* Users */}
                                <li className={`menu-item ${window.location.href.includes('/Users') ? "activeLnk" : ""}`} >
                                    <Link to="/Users"> <span><i className="fa fa-user fs-16" />משתמשים</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${window.location.href.includes('/ClientUsers') ? "activeLnk" : ""}`} >
                                    <Link to="/ClientUsers"> <span><i className="fa fa-user fs-16" />לקוחות</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${window.location.href.includes('/BlackLst') ? "activeLnk" : ""}`} >
                                    <Link to="/BlackLst"> <span><i className="fa fa-user fs-16" />Black List</span>
                                    </Link>
                                </li>

                                {/* SimplePages */}
                                <li className="menu-item">
                                    <Link to="#" className="has-chevron" onClick={() => setShowSpagesList(!showSpagesList)}>
                                        <span><i className="fas fa-folder fs-16" />עמודים</span>
                                    </Link>
                                    <ul id={"SpagesList"} className={(showSpagesList) ? "" : "collapse"} aria-labelledby="עמודים" data-parent="#side-nav-accordion">
                                        <li key={"newSp"}> <Link to={"/nswSp"} >עמוד חדש</Link>
                                        </li>

                                        {lstSimpePages?.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to={`/${item.inLinkToPage}`} onClick={() => setShowSpagesList(showSpagesList)}>
                                                        <span>
                                                            <i className="fa fa-align-justify fs-16" />
                                                            {item.pageName}
                                                        </span>
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>

                                {/* Contactpages */}
                                <li className="menu-item">
                                    <Link to="#" className="has-chevron" onClick={() => setShowContactsList(!showContactsList)}> <span><i className="fas fa-folder fs-16" />עמודי יצירת קשר</span>
                                    </Link>
                                    <ul className={(showContactsList) ? "" : "collapse"} aria-labelledby="עמודי יצירת קשר" data-parent="#side-nav-accordion">
                                        <li key={"nswCP"}> <Link to={"/nswContP"} >עמוד חדש</Link>
                                        </li>

                                        {lstContactpages?.map((item, index) => {
                                            return (
                                                <li key={index}> <Link to={`/${item.inLinkToPage}`}>
                                                    <span>
                                                        <i className="fa fa-align-justify fs-16" />
                                                        {item.pageName}
                                                    </span>
                                                </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li >

                                {/* Managment */}
                                <li className={`menu-item ${window.location.href.includes('/Manage') ? "activeLnk" : ""}`} >
                                    <Link to="/Manage"> <span><i className="fa fa-snowflake fs-16" />הגדרות</span>
                                    </Link>
                                </li>

                                <li className={`menu-item ${window.location.href.includes('/ImageGall') ? "activeLnk" : ""}`} >
                                    <Link to="/ImageGall"> <span><i className="fa fa-file-video fs-16" />מדיה</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${window.location.href.includes('/ReportsP') ? "activeLnk" : ""}`} >
                                    <Link to="/ReportsP"> <span><i className="fa fa-file-image fs-16" />דוחות</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${window.location.href.includes('/Kopons') ? "activeLnk" : ""}`} >
                                    <Link to="/Kopons"> <span><i className="fa fa-gift fs-16" />מערכת קופונים</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${window.location.href.includes('/ProgramrsMsgs') ? "activeLnk" : ""}`} >
                                    <Link to="/ProgramrsMsgs"> <span><i className="fa fa-file-image fs-16" />מטלות לביצוע</span>
                                    </Link>
                                </li>
                            </> : ""}
                    </ul>
                </Scrollbar >
            </div>
        )
    );
}

export default Sidenavigation;