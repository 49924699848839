import React, { useContext, useEffect, useState } from 'react';
import ShowMyOrder from './showMyOrder';
import { DeliveryDining, LocalMallOutlined } from '@mui/icons-material';
import { MobiOrNotContext } from '../../../../MyContexts/MobiOrNotContext';
import MyDateTime from '../../../../MySections/TimeSections/MyDateTime';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';

const OrdersHistoryItem = (props) => {
    // const [order, setOrder] = useContext(OrderContext);

    const [theOrder, setTheOrder] = useState(props.theOrder)
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext)

    const [intervalID, setIntervalID] = useState();
    const [shown, setShown] = useState(false);



    useEffect(() => {

    }, [])

    const showThisOrder = (orderDivId) => {
        setShown(!shown)
        props.showThisOrder(orderDivId);
    }


    const orderConfirmd = (ord) => {
        props.orderConfirmd(ord);

    }

    return (
        <div key={props.ordrIndex} className={"overflowHid pointing offOrdItm"}>
            <div className={"headsline overflowHid"} onClick={() => showThisOrder(`ordDetailsDivCls_${props.ordrIndex}`)}>

                {!mobiOrNot ?
                    <>

                        {theOrder.ord.OrderType == "Take" ?
                            <LocalMallOutlined sx={{ color: 'var(--btnBlue)' }} />
                            :
                            <DeliveryDining sx={{ color: 'var(--btnBlue)' }} />
                        }
                        <span className={"spaceMargeRight"}>

                            {(MyDateTime.getDate(theOrder.ord.dateCreated))}
                        </span>
                        <span className={"spaceMargeRight"}>
                            {theOrder.buiss.buissName}, {theOrder.ord.OrderType == "Take" ? "איסוף" : "משלוח"}
                        </span>

                        <span className={"spaceMargeRight bold onLeft"}>
                            {OrderFunctions.getOrderTotalPrice(theOrder.ord)} ₪
                        </span>
                        <span className={"spaceMargeRight bold onLeft"}>
                            {theOrder.payt === "1" ? "אשראי" : "מזומן"}
                        </span>

                    </>
                    :
                    <>

                        <div className={"col-12 overflowHid noPadding"}>
                            <div className={"col-2 onRight overflowHid noPadding" }>
                                {theOrder.ord.OrderType == "Take" ?
                                    <LocalMallOutlined sx={{ color: 'var(--btnBlue)' }} />
                                    :
                                    <DeliveryDining sx={{ color: 'var(--btnBlue)' }} />
                                }
                            </div>
                            <div className={"col-7 onRight overflowHid noPadding"}>
                                <span className={"col-12 onRight noPadding"}>
                                    {(MyDateTime.getDate(theOrder.ord.dateCreated))}
                                </span>
                                <span className={"col-12 onRight overflowHid noPadding"}>
                                    {theOrder.buiss.buissName}
                                </span>
                                <span className={"col-12 onRight overflowHid noPadding"}>
                                    {theOrder.ord.OrderType == "Take" ? "איסוף" : "משלוח"}
                                </span>
                            </div>
                            <div className={"col-3 onRight overflowHid noPadding"}>
                                <span className={"spaceMargeRight bold onLeft overflowHid noPadding"}>
                                    {OrderFunctions.getOrderTotalPrice(theOrder.ord)} ₪
                                </span>
                                <span className={"spaceMargeRight bold onLeft overflowHid noPadding"}>
                                    {theOrder.payt === "1" ? "אשראי" : "מזומן"}
                                </span>

                            </div>
                        </div>

                    </>

                }

            </div>
            <div className={"ordDetailsDiv hideMe margTopBig"} id={`ordDetailsDivCls_${props.ordrIndex}`}>

                <div className="col-12">
                    <div className="row">
                        <div className="col-12 overflowHid">


                        </div>

                        <div className={"col-12 pointing"}>
                            <div className={"big bold onRight "}>{theOrder.buiss.buissName}</div>
                        </div>

                        <div className="col-12">
                            <div className={"openOrdBx orderLINE "} >
                                <div className={"big bold"}>מספר הזמנה: {theOrder.ord.orderNUM}</div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className={"openOrdBx orderLINE"}>
                                <div className={"big bold"}>סטטוס: {theOrder.ord.orderStatus}</div>
                            </div>
                        </div>

                        <ShowMyOrder theOrder={theOrder.ord} />
                    </div>
                </div>
            </div>

        </div >

    )

}

export default OrdersHistoryItem