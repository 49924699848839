import React, { useContext, useEffect, useState } from 'react';
// import Breadcrumb from './Breadcrumb'

import utilsDeliveryDetails from '../../../../MyConns/_Utils/utilsDeliveryDetails';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import myFunctions from '../../../../myFunctions';
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import { MobiOrNotContext } from '../../../MyContexts/MobiOrNotContext';




const DeliveryDetails = (props) => {

    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [theDeliveryDetails, setTheDeliveryDetails] = useState();
    const [thePopUp, setThePopUp] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTheDeliveryDetails();
    }, [])


    const getTheDeliveryDetails = async () => {
        let delDet = await utilsDeliveryDetails.getDeliveryDetailsByOrdNum(props.ordNum);
        if (delDet !== null) {
            setTheDeliveryDetails(delDet)
            setLoading(false)
        }
        else {
            props.okClick();
        }
    }

    const updateTheDeliveryDetails = () => {

        props.okClick();
    }


    return (
        <div className={`${props.cssStyl ? props.cssStyl : ""}`}>
            <div className="">
                {loading ? <MySpinner_Wait /> :
                    <div className="table-responsive ms-panel-body">
                        <div className={"col-md-12 mb-6 onRight dottedLine"}>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                <span className={'bold'}>מקור:</span>
                            </div>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                <span>{props.buisName}</span>
                            </div>

                        </div>
                        <div className={"col-md-12 mb-6 onRight dottedLine"}>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                <span className={'bold'}>יעד:</span>
                            </div>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                <span>{props.AddrsTo}</span>
                            </div>

                        </div>
                        <div className={"col-md-12 mb-6 onRight dottedLine"}>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                <span className={'bold'}>מספר הזמנה: </span>
                            </div>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                <span>{theDeliveryDetails.order_id}</span>
                            </div>

                        </div>

                        <div className={"col-md-12 mb-6 onRight dottedLine"}>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                <span className={'bold'}>סטטוס משלוח: </span>
                            </div>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                <span>{myFunctions.sendiStatus(theDeliveryDetails.delivery_status)}</span>
                            </div>
                        </div>

                        <div className={"col-md-12 mb-6 onRight dottedLine"}>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                <span className={'bold'}>שם השליח: </span>
                            </div>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                <span>{theDeliveryDetails.data.driver_name}</span>
                            </div>

                        </div>
                        {(theDeliveryDetails.data.driver_phone !== null) ?
                            <div className={"col-md-12 mb-6 onRight dottedLine"}>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                    <span className={'bold'}>טלפון השליח: </span>
                                </div>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                    <span>
                                        <a href={`tel:${theDeliveryDetails.data.driver_phone}`}>
                                            {theDeliveryDetails.data.driver_phone}
                                        </a>
                                    </span>
                                </div>

                            </div> : ""}
                        {(theDeliveryDetails.data.tracker_link !== null) ?
                            <div className={"col-md-12 mb-6 onRight dottedLine"}>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                    <span className={'bold'}>טרקר: </span>
                                </div>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                    <span>
                                        <a href={`https://${theDeliveryDetails.data.tracker_link}`} target={"_blank"}>
                                            לחץ כאן
                                        </a>
                                    </span>
                                </div>

                            </div>
                            : ""}
                        {(theDeliveryDetails.data.assigned_at !== null) ?
                            <div className={"col-md-12 mb-6 onRight dottedLine"}>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                    <span className={'bold'}>צווות לשליח ב: </span>
                                </div>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                    <span>
                                        {MyDateTime.getDateAndTime(theDeliveryDetails.data.assigned_at)}
                                    </span>
                                </div>

                            </div> : ""
                        }

                        {(theDeliveryDetails.data.picked_up_at !== null) ?
                            <div className={"col-md-12 mb-6 onRight dottedLine"}>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                    <span className={'bold'}>נאסף ב: </span>
                                </div>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                    <span>
                                        {MyDateTime.getDateAndTime(theDeliveryDetails.data.picked_up_at)}
                                    </span>
                                </div>

                            </div>
                            :
                            <div className={"col-md-12 mb-6 onRight dottedLine"}>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                    <span className={'bold'}>זמן מוערך לאיסוף: </span>
                                </div>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                    <span>
                                        {MyDateTime.getDateAndTime(theDeliveryDetails.data.updated_pickup_estimation_time)}
                                    </span>
                                </div>

                            </div>

                        }
                        {(theDeliveryDetails.data.updated_dropoff_estimation_time !== null) ?
                            <div className={"col-md-12 mb-6 onRight dottedLine "}>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                    <span className={'bold'}>זמן מוערך למסירה: </span>
                                </div>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                    <span>
                                        {MyDateTime.getDateAndTime(theDeliveryDetails.data.updated_dropoff_estimation_time)}
                                    </span>
                                </div>

                            </div>
                            : ""}

                        {(theDeliveryDetails.data.delivered_at !== null) ?
                            <div className={"col-md-12 mb-6 onRight dottedLine"}>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                    <span className={'bold'}>נמסר ללקוח ב: </span>
                                </div>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                    <span>
                                        {MyDateTime.getDateAndTime(theDeliveryDetails.data.delivered_at)}
                                    </span>
                                </div>

                            </div>
                            : ""}



                        {(theDeliveryDetails.data.company_name !== null) ?
                            <div className={"col-md-12 mb-6 onRight dottedLine"}>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                    <span className={'bold'}>חברת שליחויות: </span>
                                </div>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                    <span>{theDeliveryDetails.data.company_name}</span>
                                </div>

                            </div>
                            : ""}
                        <div className={"col-md-12 mb-6 onRight dottedLine"}>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                <span className={'bold'}>מספר הזמנה בסנדימן: </span>
                            </div>
                            <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                <span>{theDeliveryDetails.data.sendi_order_id}</span>
                            </div>

                        </div>
                        {(theDeliveryDetails.data.license_plate !== null) ?
                            <div className={"col-md-12 mb-6 onRight dottedLine"}>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight leftText" : "onRight"}`}>
                                    <span className={'bold'}>לוחית רישוי: </span>
                                </div>
                                <div className={`${!mobiOrNot ? "col-md-6 mb-6 onRight":"onRight"}`}>
                                    <span>{theDeliveryDetails.data.license_plate}</span>
                                </div>

                            </div>
                            : ""}

                    </div>
                }
                <div className={"btnsDiag centerText"}>
                    {/* <button type="button"
                        className="centerMe btn btn-success btnPopRegular onRight"
                        onClick={() => { updateTheDeliveryDetails() }}>
                        עדכן
                    </button>
                    <span>&nbsp;&nbsp;</span> */}
                    <button type="button"
                        className={"centerMe btn btn-success btnPopRegular"}
                        onClick={() => { props.cancelClick() }}>
                        סגור
                    </button>
                </div>
            </div >
            {thePopUp}
        </div >
    )
}
export default DeliveryDetails;

