import React, { Component, useContext, useEffect, useState } from 'react';

import Breadcrumb from './Breadcrumb'
import ReactDOM from 'react-dom'
import { Link, useParams, useHistory } from 'react-router-dom'
import utilsOrders from '../../../../../MyConns/_Utils/utilsOrders';
import MyDateTime from '../../../../MySections/TimeSections/MyDateTime';
import SucessToast from '../../../../MySections/Toasts/SucessToast';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import PrintingStyle from '../../../../../MyFunctions/PrintingStyle';

import OrderStatuselect from '../../OrdersList-list/OrderStatuselect';
import { Button } from '@mui/material';
import PrintTemplate from 'react-print';
import { display } from '@mui/system';
import InvoicePrint from './InvoicePrint';
import TextBoxSet from '../../../../MySections/PopUps/TextBoxSet';
import parse from 'html-react-parser';
import InvoiceTable from './InvoiceTable';
import InvoiceMobiTable from './InvoiceMobiTable';


import { MobiOrNotContext } from '../../../../MyContexts/MobiOrNotContext';

import UpdateOrderDetails from '../../../../MySections/PopUps/UpdateOrderDetails';
import MySpinner_Wait from '../../../../MySections/DTechComps/MySpinner_Wait';
import Translator from '../../../../../MyFunctions/Translator';
import InputSet from '../../../../MySections/PopUps/InputSet';
import DeliveryDetails from '../../DeliveryDetails/DeliveryDetails';
import PopUpTheComp from '../../../../MySections/DTechComps/PopUpTheComp';
import { UserContext } from '../../../../MyContexts/Users_Context';
import ZikoyOnOrder from '../../../../MySections/PopUps/ZikoyOnOrder';
import LogsOrder from '../../../../MySections/PopUps/LogsOrder';
import { LibraryBooks } from '@mui/icons-material';
import myFunctions from '../../../../../myFunctions';
import ClientUserItem from '../../../ClientUsers/ClientUserItem';


const Content = (props) => {

    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [user, setUser] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [theOrder, setTheOrder] = useState([]);
    const [times, setTimes] = useState([]);
    const [printMe, setPrintMe] = useState([false]);
    const [thePopUp, setThePopUp] = useState("");
    const [arabic, setArabic] = useState("عربيه");
    const [errMsg, setErrMsg] = useState("");
    let idParam = useParams();

    const history = useHistory();


    useEffect(() => {

        loadTheOrder();
    }, [])


    const loadTheOrder = async () => {
        if (idParam.id) {
            let Orderloaded = await utilsOrders.getOrder(idParam.id);
            if (Orderloaded.errMsg) {
                setErrMsg(Orderloaded.errMsg);
            }
            else {
                setTheOrder(Orderloaded);
                getTimes(Orderloaded)
                setLoading(false);
            }
        }
        else {
            history.push("/Orders");
        }

    }

    const getTimes = (ord) => {
        setInterval(() => {
            let theItm = MyDateTime.getTimeLeftTilThen(ord.ord.dateCreated, ord.ord.OrderTimes);
            setTimes(theItm)

        }, 1000);
    }



    const updateTheOrder = async () => {

        let updteme = await utilsOrders.updateOrder(theOrder.ord._id, theOrder);
        loadTheOrder();
        SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
    }

    const getAddress =
        (theOrder.ord?.OrderType !== "Take") ?
            <>
                <div className={"dottedLightGray"}></div>
                <div className={"bold big "}>
                    &nbsp;
                    {theOrder.ord?.deliveryAddress.street}
                    &nbsp;

                    {(!theOrder.ord?.deliveryAddress.street.endsWith(theOrder.ord?.deliveryAddress.num)) ?
                        theOrder.ord?.deliveryAddress.num
                        : ""}
                    &nbsp;
                    &nbsp;
                    {(theOrder.ord?.deliveryAddress.appartment) ?
                        `דירה: ${theOrder.ord?.deliveryAddress.appartment}`
                        : ""}
                    &nbsp;
                    &nbsp;
                    {(theOrder.ord?.deliveryAddress.ent) ?
                        `כניסה: ${theOrder.ord?.deliveryAddress.ent}`
                        : ""}
                    &nbsp;
                    &nbsp;
                    {(theOrder.ord?.deliveryAddress.flor) ?
                        `קומה: ${theOrder.ord?.deliveryAddress.flor}`
                        : ""}
                    &nbsp;
                    &nbsp;
                    {theOrder.ord?.deliveryAddress.city || "אשדוד"}


                </div>
                <div className={"dottedLightGray"}></div>

                {(theOrder.ord?.deliveryAddress.remarks) ?
                    <>
                        <div className={"bold regular maxTxtAreaHeight"}>
                            הערות: {theOrder.ord?.deliveryAddress.remarks}
                        </div>
                        <div className={"dottedLightGray"}></div>
                    </>

                    : ""}

            </> : "";



    const clientDetails =
        <>
            <div className={"col-12 spac spacePadBig specialRow"}>
                <div className={"bold big"}>שם הלקוח: {theOrder.usr?.firstName}  {theOrder.usr?.lastName}</div>
                <div className={"bold"}>טלפון: {theOrder.usr?.phone}</div>
                {theOrder.usr?.email != "" ? <div className={"bold"}>אימייל: {theOrder.usr?.email}</div> : ""}

                {(user.permiss === "admin") &&
                    <div style={{ position: 'absolute', top: 0, left: 0 }}>
                        <div className={"onLeft spaceMargeTopbig "}>
                            <Button className={"btnMediumMnu"} variant="contained" onClick={() => showClientDetails()}>
                                פרטי לקוח
                            </Button>
                        </div>
                    </div>

                }
            </div>

        </>;


    const popUpdateOrder = () => {
        setThePopUp(
            <UpdateOrderDetails Title={"עריכת פרטים"}
                theOrder={theOrder} inLabel={""}
                okClick={(theOrderBk) => popMe(theOrderBk)}
                cancelClick={() => setThePopUp()}
                errorMsg={""}
            />)


    }
    const popZikoyOrder = () => {
        setThePopUp(
            <ZikoyOnOrder Title={"יצירת זיכוי"}
                theOrder={theOrder}
                inLabel={"תאור הזיכוי"}
                inLabel2={"סכום הזיכוי"}
                okClick={(theOrderBk) => { popMe(theOrderBk); setThePopUp() }}
                cancelClick={() => setThePopUp()}
                errorMsg={""}
            />)


    }


    const showClientDetails = () => {
        if (theOrder.usr) {
            setThePopUp(
                <PopUpTheComp
                    closePop={() => { setThePopUp() }}
                    cssStyl={"popSetter"}
                    popDiagStyle={"popMax popMax800"}
                    errorMsg={""}
                    okClick={() => {  }}
                    btnClick1={true}
                    Title={["פרטי לקוח"]}
                    titleCss={"centerText"}
                    CancelRounded={"parentX"}
                    theInsideComp={
                        <ClientUserItem noBack={true} clentId={`${theOrder.usr._id ? theOrder.usr._id : ""}`} />}
                />
            )
        }
    }

    const popMe = (theOrderBk) => {
        // setTheOrder(theOrderBk)
        // // console.log(theOrderBk)
        // updateTheOrder();
    }

    const printIt = () => {
        setPrintMe(true)

        var content = document.getElementById("printingCloser");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML + PrintingStyle.mediaprint());
        pri.document.close();
        pri.focus();
        pri.print();


        // window.print()
        setPrintMe(false)
    }



    const ShowInArab = () => {
        if (arabic === "عربيه") {
            setArabic("עברית");
            setTheOrder(Translator.Arabic(theOrder));

        } else {
            setArabic("عربيه");
            setTheOrder(Translator.BackToHeb(theOrder));
        }
    }


    const setNewStatus = async (orderId, statBack) => {

        if (statBack !== "מבוטל") {
            let updteme = await utilsOrders.updateStatus(orderId, statBack);
            loadTheOrder();
        }
        else {
            cancelOrderapproval(orderId);
        }

    }
    const cancelOrderapproval = (orderId) => {

        setThePopUp(<InputSet ContentText={`מהי סיבת הביטול?`}
            okClick={(inputTxt) => okCancel(inputTxt, orderId)}
            cancelClick={() => { setThePopUp() }}
            errorMsg={"חובה להזין סיבה לביטול"} />)

        const okCancel = async (cancelReason, orderId) => {
            let updteme = await utilsOrders.updateStatusToCancel(orderId,
                "מבוטל", cancelReason);
            loadTheOrder();
        }

    }

    const setRemarks = () => {
        setThePopUp(
            <TextBoxSet Title={"הערות הזמנה"}
                textFirst={theOrder.ord.orderRemarks} inLabel={""}
                okClick={(textBack) => setRemarksApprov(textBack)}
                cancelClick={() => setThePopUp()}
                errorMsg={""}
            />)
    }


    const setRemarksApprov = (textBack) => {
        // alert(textBack)


        theOrder.ord.orderRemarks = textBack;

        setTheOrder(theOrder);
        getTimes(theOrder)

    }

    const addOrderItem = () => {
        setThePopUp(
            // <RestMnu buisMenu={restPage.buisMenu} nameBuis={restPage.buissName} restPage={restPage}
            //     doDeliver={temp_doDeliver}
            //     doTakeAway={temp_doTakeAway}
            //     setShowHideMoreDet={() => setShowHideMoreDet("showOnLeft")}
            //     dispOnlyDelivery={dispOnlyDelivery} BuisId={restPage._id}
            //     setDispOnlyDelivery={() => setDispOnlyDelivery(!dispOnlyDelivery)} />

        )
    }
    const popOrderLog = (orderNUM) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"popSetter"}
                popDiagStyle={"popMax popMax800"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["לוג הזמנה " + orderNUM]}
                titleCss={"centerText"}
                theInsideComp={
                    <LogsOrder cssStyl={"popSetter"}
                        ordNum={orderNUM}

                        cancelClick={() => setThePopUp()}
                        popDiagStyle={" popMax popMax800"}
                    />}
            />



        )
    }

    const PopDeliveryDetails = (orderNUM, buisName, AddrsTo) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"OrderDispPop"}
                popDiagStyle={"OrderDispValPop"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["פרטי השליחות"]}
                theInsideComp={
                    <DeliveryDetails cssStyl={"OrderDisplayPop"}
                        cancelClick={() => setThePopUp()}
                        okClick={() => { setThePopUp() }}
                        Title={[""]}
                        ordNum={orderNUM}
                        buisName={buisName}
                        AddrsTo={AddrsTo}
                    />}
            />

        )
    }

    const topBtnsROw = () => {
        return (
            <>
                <div className="col-12 overflowHid spaceMargeBottombig">
                    <div className="col-9 overflowHid noPadding onRight">
                        <div className="col-stretch">

                            <div className={"spaceMargeTopbig "}>
                                <Button className={"btnMediumMnu"} variant="contained" onClick={() => history.push('/Orders')}>
                                    לכל ההזמנות
                                </Button>
                            </div>
                        </div>

                        {(user.permiss === "admin") &&
                            <div className="col-stretch">
                                <div className={"onLeft spaceMargeTopbig "}>
                                    <Button className={"btnMediumMnu"} variant="contained" onClick={() => popZikoyOrder()}>
                                        זיכוי בהזמנה
                                    </Button>
                                </div>
                            </div>

                        }

                        {theOrder.rst.arabic &&
                            <div className="col-stretch">

                                <div className="onLeft spaceMargeTopbig ">
                                    <Button className={"btnMediumMnu"} variant="contained" onClick={() => ShowInArab()}>
                                        {arabic}
                                    </Button>

                                </div>
                            </div>}
                    </div>

                    <div className="col-3 overflowHid onRight">
                        <div className="col-stretch onLeft">
                            <div className="spaceMargeTopbig ">
                                <Button className={"btnMediumMnu"} variant="contained" onClick={() => printIt()}>
                                    הדפס הזמנה
                                </Button>

                            </div>
                        </div>
                    </div>

                </div>

                <div className={`col-md-12 overflowHid`}>
                    <div className="col-stretch onRight">
                        <Button className={"btnMediumMnu"} variant="contained" onClick={() => setRemarks()}>הערות</Button>
                    </div>

                    {(user.permiss === "admin") &&
                        <>
                            <div className="col-stretch spaceMargeRight">
                                <Button className={"btnMediumMnu"} variant="contained"
                                    onClick={() => popOrderLog(theOrder.ord?.orderNUM)}
                                    startIcon={<LibraryBooks sx={{ color: 'white' }} />}
                                >
                                </Button>
                            </div>
                            {/* <div className="col-stretch spaceMargeRight">
                                <Button className={"btnMediumMnu"} variant="contained" onClick={() => addOrderItem()}>+</Button>
                            </div> */}
                        </>
                    }

                </div >


            </>

        )

    }


    return (
        loading ? <MySpinner_Wait /> :

            (errMsg !== "") ? <p>{errMsg}</p> :
                <>
                    <div className="no_print">
                        <div className="ms-content-wrapper">
                            <div className={`row no_print`}>
                                <div className="col-md-12 inBGWhite">
                                    <div><Breadcrumb /></div>
                                    {topBtnsROw()}
                                    <div className="col-12">
                                        <div className="ms-panel-header header-mini centerText fullRow">
                                            <h3 className={"spaceMargeTopbig"}>{`${theOrder.rst?.buissName}`}</h3>
                                        </div>

                                        <div className="col-md-12 overflowHid noPadding ">

                                            <div className="col-md-6 overflowHid ordDetailsDiv onRight">

                                                <div className="col-md-12 onRight noPadding">

                                                    <div className="col-stretch onRight noPadding fullOnPrint">
                                                        <h4 className="bold noPadding noMargin">
                                                            {(theOrder.ord?.OrderType === "Deliver") ? "משלוח" : "איסוף"}
                                                        </h4>
                                                    </div>
                                                    <div className="col-stretch onLeft noPadding fullOnPrint ">
                                                        <h4 className={"bold noPadding noMargin"}>
                                                            {`הזמנה #${theOrder.ord?.orderNUM}`}</h4>
                                                    </div>
                                                </div>

                                                {/* Invoice To */}
                                                <div className="row align-items-center">
                                                    <div className={"col-md-12 onlyOnPrint"}>
                                                        <div className="invoice-address">
                                                            {(theOrder.ord?.OrderType === "Deliver") ?
                                                                getAddress : ""}
                                                            {clientDetails}
                                                            <div className={"dottedLightGray"}></div>
                                                        </div>
                                                    </div>

                                                    <div className={"col-md-12"}>
                                                        <div className="invoice-address">
                                                            {(theOrder.ord?.OrderType === "Deliver") ?
                                                                getAddress : ""}

                                                            {clientDetails}

                                                            <div className={"dottedLightGray"}></div>

                                                        </div>


                                                    </div>

                                                </div>


                                                <div className={"big bold"}>
                                                    סה"כ: {(theOrder.ord != null) ? `₪${OrderFunctions.getOrderTotalPrice(theOrder.ord)}` : ""}
                                                </div>
                                                <div className={"bold"}>
                                                    אופן תשלום: {OrderFunctions.payingTYpe(theOrder)}
                                                </div>
                                                <div className={"bold"}>
                                                    סטטוס תשלום: {theOrder.ord?.paymentStatus}
                                                </div>
                                            </div>



                                            <div className="col-md-6 overflowHid onRight">
                                                <div className="col-md-12 overflowHid ordDetailsDiv onRight">

                                                    <div className="col-md-12 onRight fullRow fullOnPrint">
                                                        <div className={"bold textRight"}>יצירת ההזמנה: {MyDateTime.getDateAndTime(theOrder.ord?.dateCreated)}</div>
                                                    </div>

                                                    {(theOrder.ord?.lastUpdate) ? <>
                                                        <div className="col-md-12 onRight fullRow fullOnPrint">
                                                            <div className={"bold textRight "}>עדכון אחרון: {MyDateTime.getDateAndTime(theOrder.ord?.lastUpdate)}</div>
                                                        </div>
                                                        <div className="col-md-12 onRight fullRow fullOnPrint">
                                                            <div className={"bold textRight "}>עודכן ע"י: {theOrder.ord?.lastUpdaterBy || ""}</div>
                                                        </div>
                                                    </>
                                                        : ""}

                                                    {(theOrder.ord?.dateClosed) ? <>
                                                        <br />
                                                        <div className="col-md-12 onRight fullOnPrint">
                                                            <div className={"bold textRight"}>סגירת ההזמנה: {MyDateTime.getDateAndTime(theOrder.ord?.dateClosed)}</div>
                                                        </div>
                                                        <br />

                                                        <div className="col-md-12 onRight fullOnPrint">
                                                            <div className={"bold textRight"}>נסגר על ידי: {theOrder.ord?.closedBY}</div>
                                                        </div>
                                                        <br />
                                                    </>
                                                        : ""}

                                                </div>
                                                <div className="col-md-12 overflowHid ordDetailsDiv onRight">
                                                    <div className="col-md-12 spaceMargeBottombig">
                                                        <div className="invoice-date overflowHid">
                                                            {
                                                                (times.theEnd) ?
                                                                    ((theOrder.ord?.orderStatus != "סגור") ? <div>
                                                                        <div className={"myButton noPadding"} style={{ backgroundColor: times.theCol, color: times.theForCol, fontWeight: "bold" }} id={theOrder.ord?._id + "_Count"}>
                                                                            <div className={"fullRow centerText spacePadBig"}>
                                                                                <div className={"centerMargin asTable overflowHid"}>
                                                                                    <div className={"onRight spacePaddingRight"}>הסתיים הזמן לפני</div>
                                                                                    <div className={"onRight spaceMargeRight"}>
                                                                                        <div id={theOrder.ord?._id + "_Cnt"}>
                                                                                            {times.theTm}<br />
                                                                                            {/* {MyDateTime.getTimeLeftTilThen( theOrder.dateCreated,  theOrder.OrderTimes)} */}
                                                                                        </div>
                                                                                        {/* {counterDown( theOrder._id)} */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> : "") :
                                                                    <div>
                                                                        <div className={"myButton noPadding"} style={{ backgroundColor: times.theCol, color: times.theForCol, fontWeight: "bold" }} id={theOrder.ord?._id + "_Count"}>
                                                                            <div className={"fullRow centerText spacePadBig"}>
                                                                                <div className={"centerMargin asTable overflowHid"}>
                                                                                    <div className={"onRight spacePaddingRight"}>זמן לביצוע</div>
                                                                                    <div className={"onRight spaceMargeRight"}>
                                                                                        <div id={theOrder.ord?._id + "_Cnt"}>
                                                                                            {times.theTm}<br />
                                                                                            {/* {MyDateTime.getTimeLeftTilThen( theOrder.dateCreated,  theOrder.OrderTimes)} */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* {counterDown( theOrder._id)} */}
                                                                        </div>
                                                                    </div>


                                                            }
                                                        </div>
                                                    </div>

                                                    <div className={"col-md-12 overflowHid"}>

                                                        <div className={"row"}>
                                                            <div className={"col-6 onRight pointing bigStatus"}>
                                                                <OrderStatuselect orderStatus={theOrder.ord?.orderStatus}
                                                                    setOrderStatus={(statBack) => setNewStatus(theOrder.ord?._id, statBack)}
                                                                />
                                                            </div>

                                                            <div className={"col-6 onRight veryBig pointing"}>

                                                                {(theOrder.ord?.OrderType !== "Take" && theOrder.ord?.sendiStatus && theOrder.ord?.sendiStatus !== "") ?
                                                                    <>
                                                                        <div className={"fullRow"}>
                                                                            {
                                                                                (theOrder.rst?.sendiRestName && theOrder.rst?.sendiRestName != "") ?
                                                                                    <Button className={`${myFunctions.getSendiColors(theOrder.ord?.sendiStatus)} btnDeliveryDetail`}
                                                                                        variant="contained"
                                                                                        onClick={() => PopDeliveryDetails(theOrder.ord.orderNUM,
                                                                                            theOrder.rst?.buissName,
                                                                                            (theOrder.ord.deliveryAddress.street + " " +
                                                                                                theOrder.ord.deliveryAddress.num + " " +
                                                                                                theOrder.ord.deliveryAddress.city))}>
                                                                                        {theOrder.ord.sendiStatus}
                                                                                    </Button> :
                                                                                    <Button className={`btnDeliveryDetail`}
                                                                                        variant="contained"
                                                                                        onClick={() => { }}>
                                                                                        שליח פרטי
                                                                                    </Button>
                                                                            }
                                                                            {/* <span className={"orderItmTxt sendiStatus big"}>{theOrder.ord.sendiStatus}</span> */}
                                                                        </div>
                                                                    </>
                                                                    : ""}
                                                            </div>
                                                            {theOrder.ord?.orderStatus === "מבוטל" && theOrder.ord?.cancelReason ?
                                                                <div className={"col-md-12 overflowHid"}>
                                                                    <span className={"bold big"}>
                                                                        סיבת הביטול: {theOrder.ord?.cancelReason}
                                                                    </span>
                                                                </div>

                                                                : ""}
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>


                                        {!mobiOrNot ?
                                            <InvoiceTable theOrder={theOrder} arabic={(arabic === "עברית") ? true : false} /> :
                                            <InvoiceMobiTable theOrder={theOrder} arabic={(arabic === "עברית") ? true : false} />}
                                    </div>
                                </div>
                            </div>

                            {
                                (arabic === "עברית") ? "" : <div className="ms-panel-header approveLine no_print">
                                    <button type="button" className="onLeft btn btn-primary spaceMargeTopbig" onClick={() => updateTheOrder()}>שמור</button>
                                </div>
                            }

                        </div>
                        {thePopUp}
                    </div >

                    <div id={"printingCloser"} className="onlyOnPrint printingCloser">
                        <InvoicePrint theOrder={theOrder} arabic={(arabic === "עברית") ? true : false} />
                    </div>

                    <iframe id={"ifmcontentstoprint"}></iframe>
                </>


    );

}


export default Content;
