import React, { useContext, useEffect, useState } from 'react'

import { TheMasterContext } from '../../../../MyContexts/Master_Context';
import OpenDelay from '../../../../MySections/DTechComps/OpenDelay';
import utilsUsers from '../../../../../MyConns/_Utils/utilsUsers';


const BlockDelAndPicks = (props) => {
    const [theMaster] = useContext(TheMasterContext);
    const [blockDeliverys, setBlockDeliverys] = useState(theMaster.blockDeliverys);
    const [blockPickUps, setBlockPickUps] = useState(theMaster.blockPickUps);

    useEffect(() => {
    }, [])



    const setBlckDeliverys = () => {
        let temp = { ...blockDeliverys }
        temp.block = !temp.block;
        theMaster["blockDeliverys"] = temp;
        setBlockDeliverys(temp)

    }
    const setBlckPickUps = () => {
        let temp = { ...blockPickUps }
        temp.block = !temp.block;
        theMaster["blockPickUps"] = temp;
        setBlockPickUps(temp)
    }


    const setOpenDeliveryDelay = (selected) => {
        let temp = { ...blockDeliverys }
        temp.block = selected.delaydVal === "0" ? false : true;
        temp.delay = selected;
        theMaster["blockDeliverys"] = temp;
        setBlockDeliverys(temp)
    }
    const setOpenTakeDelay = (selected) => {
        let temp = { ...blockPickUps }
        temp.block = selected.delaydVal === "0" ? false : true;
        temp.delay = selected;
        theMaster["blockPickUps"] = temp;
        setBlockPickUps(temp)
    }


    return (
        <>
            <div className={"col-md-12 mb-2 fullRow"}>
                <div className={"col-md-6 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>חסימת כלל המשלוחים</label>
                    <span className={"explanation mediumst fullROw"}>במידה ופעיל, לא יתאפשרו הזמנת משלוחים לכלל העסקים</span>
                    <span className={"explanation mediumst fullROw"}>במידה וכבוי, יתאפשרו הזמנת משלוחים לכל העסקים שבהם יש אפשרות משלוחים</span>
                    <label className="ms-switch">
                        <input type="checkbox" id={"validationCustom5_3"} checked={blockDeliverys.block}
                            onChange={(e) => setBlckDeliverys()} />
                        <span className="ms-switch-slider ms-switch-primary round" />
                    </label> <span className={"spHead"}>משלוחים</span>
                </div>
                {/* <div className={"col-md-3 onRight spaceTopBig"}>
                    {(!theMaster.blockDeliverys.block &&
                        <OpenDelay key={'OpenDelaydrpTake1'} closeDelay={theMaster.blockDeliverys.delay || ""}
                            onLeft={"onRight"} typeToDelay={"blockDeliverys"}
                            delayUpdate={(selected) => setOpenDeliveryDelay(selected)} />
                    )}
                </div> */}
            </div>

            <div className={"col-md-12 mb-2 fullRow"}>
                <div className={"col-md-6 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>חסימת כלל האיסופים</label>
                    <span className={"explanation mediumst fullROw"}>במידה ופעיל, לא יתאפשרו הזמנת איסופים לכלל העסקים</span>
                    <span className={"explanation mediumst fullROw"}>במידה וכבוי, יתאפשרו הזמנת איסופים לכל העסקים שבהם יש אפשרות איסוף</span>
                    <label className="ms-switch">
                        <input type="checkbox" id={"validationCustom5_3"} checked={blockPickUps.block}
                            onChange={(e) => setBlckPickUps()} />
                        <span className="ms-switch-slider ms-switch-primary round" />
                    </label> <span className={"spHead"}>איסופים</span>
                </div>

                {/* <div className={"col-md-3 onRight spaceTopBig"}>
                    {
                        <OpenDelay key={'OpenDelaydrpTake'} closeDelay={theMaster.blockPickUps.delay || ""}
                            onLeft={"onRight"} typeToDelay={"blockPickUps"}
                            delayUpdate={(selected) => setOpenTakeDelay(selected)} />
                    }
                </div> */}
            </div>
           
        </>

    );
}

export default BlockDelAndPicks;