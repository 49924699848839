import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
import utilsMaster from '../../../../MyConns/_Utils/utilsMaster';
import Breadcrumb from './Breadcrumb';

import { useHistory } from "react-router-dom";
import BasicPopUp from '../../../MySections/PopUps/BasicPopUp';
import SucessToast from '../../../MySections/Toasts/SucessToast';
import TabWebSiteDetails from './parts/TabWebSiteDetails';
import { TheMasterContext } from '../../../MyContexts/Master_Context';
import TabSiteDetails from './parts/TabSiteDetails';
import TabMainMenu from './parts/TabMainMenu';
import TabGallerys from './parts/TabGallerys';
import TabFooter from './parts/TabFooter';
import DeliverMaster from './parts/DeliverMaster';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import TabPopUps from './parts/TabPopUps';
import { UserContext } from '../../../MyContexts/Users_Context';
import myFunctions from '../../../../myFunctions';
import BlockDelAndPicks from './parts/BlockDelAndPicks';


const MasterSet = (props) => {
    const history = useHistory();
    const [user, setUser] = useContext(UserContext)
    const [theMaster, setTheMaster] = useContext(TheMasterContext);
    const [loading, setLoading] = useState(true);
    const [thePopUp, setThePopUp] = useState("");
    const [mastr, setMastr] = useState("");

    let idParam = useParams();

    useEffect(() => {

        getMaster();
        setMastr(myFunctions.setGetCurrnetTab("Get", "mastr") || "tab1");

    }, [])



    const getMaster = async () => {
        let mst = await utilsMaster.getMaster();
        setTheMaster(mst);
        setLoading(false);
    }


    const updateTheMaster = async () => {
      
        let rstLst = await utilsMaster.updateTheMaster(theMaster);
        // setRest(rstLst);
        setTheMaster({ ...theMaster })
        SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
        setLoading(false);
    }


    return (
        <div className="ms-content-wrapper SetBlock">
            {loading ?
                <MySpinner_Wait />
                :
                <div className="col-md-12">
                    <Breadcrumb />
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6>MASTER</h6>
                        </div>
                        <div className="ms-panel-body clearfix">
                            <Tab.Container defaultActiveKey={mastr}>

                                <Nav variant="tabs" className="nav nav-tabs d-flex nav-justified mb-2">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1" onClick={() => myFunctions.setGetCurrnetTab("Set", "mastr", "tab1")} >פרטי האתר</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2" onClick={() => myFunctions.setGetCurrnetTab("Set", "mastr", "tab2")}>תפריט ראשי</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab5" onClick={() => myFunctions.setGetCurrnetTab("Set", "mastr", "tab5")}>גלריות</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="tab3">פוטר</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab4" onClick={() => myFunctions.setGetCurrnetTab("Set", "mastr", "tab4")}>פופאפ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab6" onClick={() => myFunctions.setGetCurrnetTab("Set", "mastr", "tab6")}>הגדרת משלוחים</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab7" onClick={() => myFunctions.setGetCurrnetTab("Set", "mastr", "tab7")}>חסימה יזומה</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    {/* פרטי האתר */}
                                    <Tab.Pane eventKey="tab1">
                                        <div className="form-row tabsInputs ">
                                            <TabSiteDetails />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        <div className="form-row tabsInputs ">
                                            <TabMainMenu />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab5">
                                        <div className="form-row tabsInputs ">
                                            <TabGallerys />
                                        </div>
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="tab3">
                                        <div className="form-row tabsInputs ">
                                            <TabFooter />
                                        </div>
                                    </Tab.Pane> */}
                                    <Tab.Pane eventKey="tab4">
                                        <div className="form-row tabsInputs ">
                                            <TabPopUps />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab6">
                                        <div className="form-row tabsInputs ">
                                            <DeliverMaster />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab7">
                                        <div className="form-row tabsInputs ">
                                            <BlockDelAndPicks />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>

                    </div>
                </div>}

            <div className="ms-panel-header approveLine">
                <button type="button" className="onLeft btn btn-primary" onClick={() => { updateTheMaster(); setLoading(true) }}>שמור</button>
            </div>
            {thePopUp}
        </div>
    );
}


export default MasterSet;