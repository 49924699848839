import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import Breadcrumb from './Breadcrumb'

import { Checkbox, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import { UserContext } from '../../MyContexts/Users_Context';
import { AddBoxOutlined, LocalConvenienceStoreOutlined, Visibility } from '@mui/icons-material';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { DatePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/he';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import utilsKopons from '../../../MyConns/_Utils/utilsKopons';
import myFunctions from '../../../myFunctions';
import MyDateTime from '../../MySections/TimeSections/MyDateTime';
import MultiLIstKoponSelect from './MultiLIstKoponSelect';
import MultiUpdateCheck from '../../MySections/PopUps/MultiUpdateCheck';
import PopUpTheComp from '../../MySections/DTechComps/PopUpTheComp';
import KoponDetails from './KoponDetails';
import DateSelection from '../../MySections/TimeSections/DateSelection';
import NumberSelection from './NumberSelection';
import KoponActivatorsList from './KoponActivatorsList';
import SucessToast from '../../MySections/Toasts/SucessToast';
import InputSet from '../../MySections/PopUps/InputSet';



const AllKopons = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [koponsList, setKoponsList] = useState([]);
    const [sortUpside, setSortUpside] = useState(false);
    const [thePopUp, setThePopUp] = useState("");
    const [multiSelectStatus, setMultiSelectStatus] = useState("-1");
    const [selectAll, setSelectAll] = useState(false);
    const [multiSelect, setMultiSelect] = useState([]);
    let multiKoponValid = new Date();
    let multiKoponsMount = 50;


    useEffect(() => {
        getTheList();
        setLoading(false)
    }, [])

    const getTheList = async () => {

        let thelst = (props.txtSearch != null && props.txtSearch != "") ?
            await utilsKopons.getKoponsBySearch(props.txtSearch) :
            await utilsKopons.getKopons();

        setKoponsList(thelst);
        setMultiSelect(thelst.map((itm, indx) => { return { indx, checked: false } }))

    }


    const setTheMultiKoponValid = (dateVal) => {
        multiKoponValid = dateVal;
    }

    const setTheMultiKoponsMount = (dateVal) => {
        multiKoponsMount = dateVal;
    }



    const rowCellClick = (koponId) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"KoponDispPop"}
                popDiagStyle={"KoponDetValPop"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["פרטי קופון"]}
                theInsideComp={
                    <KoponDetails cssStyl={"KoponDetailsPop"}
                        cancelClick={() => setThePopUp()}
                        okClick={() => { props.refreshDisplay(); setThePopUp() }}
                        kopoinId={koponId}
                        Title={[""]}
                    />}
            />
        )
    }

    const orderBythis = (byWhat) => {
        let lstSorted;

        lstSorted = (sortUpside) ? myFunctions.sortAsc(koponsList, byWhat) : myFunctions.sortDsc(koponsList, byWhat);

        setSortUpside(!sortUpside);

        setKoponsList(lstSorted);
    }


    const updateMulti = (i) => {
        let tempo = [...multiSelect];
        tempo[i].checked = !tempo[i].checked
        setMultiSelect(tempo)
        setSelectAll(false)
    }

    const updateSelAll = () => {
        let tempo = multiSelect.map((itm, indx) => { return { indx, checked: !selectAll } });
        setMultiSelect(tempo)
        setSelectAll(!selectAll)
    }


    const checkApproveUpdate = () => {
        if (multiSelectStatus !== "שינוי תפוגה" &&
            multiSelectStatus !== "שינוי כמות" &&
            multiSelectStatus !== "מחק") {
            setThePopUp(<MultiUpdateCheck title={`האם בטוח לשנות את הנבחרים ל${multiSelectStatus}?`} sucecssFunc={() => updateTheSelected()} />)
        }
        else {
            if (multiSelectStatus === "שינוי תפוגה") {
                setThePopUp(<PopUpTheComp
                    closePop={() => { setThePopUp() }}
                    cssStyl={"KoponDispPop"}
                    popDiagStyle={"KoponDetValPop"}
                    errorMsg={""}
                    okClick={() => {
                        setThePopUp(<MultiUpdateCheck key={new Date()} title={`האם בטוח לשנות את הנבחרים ל${multiSelectStatus}
                         ${MyDateTime.getDate(multiKoponValid)}?`} sucecssFunc={() => updateTheSelected(multiKoponValid)} />)

                    }}
                    btn1ClickShow={true}
                    BtnTxt={"אישור"}
                    btn2ClickShow={true}
                    btnClick2={() => setThePopUp()}
                    Title={["שינוי תוקף"]}
                    theInsideComp={
                        <DateSelection
                            setTheDate={(dateVal) => { setTheMultiKoponValid(dateVal) }} currDt={multiKoponValid} />
                    }
                />
                )


            }
            else if (multiSelectStatus === "שינוי כמות") {
                setThePopUp(<PopUpTheComp
                    closePop={() => { setThePopUp() }}
                    cssStyl={"KoponDispPop"}
                    popDiagStyle={"KoponDetValPop"}
                    errorMsg={""}
                    okClick={() => {
                        setThePopUp(<MultiUpdateCheck key={new Date()} title={`האם בטוח לשנות את הנבחרים ל${multiSelectStatus}
                        ${multiKoponsMount}?`} sucecssFunc={() => updateTheSelected(multiKoponsMount)} />)

                    }}
                    btn1ClickShow={true}
                    BtnTxt={"אישור"}
                    btn2ClickShow={true}
                    btnClick2={() => setThePopUp()}
                    Title={["שינוי כמות קופונים"]}
                    theInsideComp={
                        <NumberSelection
                            setTheNumber={(dateVal) => { setTheMultiKoponsMount(dateVal) }} currDt={multiKoponsMount} />
                    }
                />
                )


            }
            else if (multiSelectStatus === "מחק") {
                setThePopUp(<MultiUpdateCheck key={new Date()} title={`האם בטוח למחוק את הנבחרים?`}
                    sucecssFunc={() => deleteTheSelected(multiKoponsMount)} />)


            }
        }

    }

    const updateTheSelected = (newVal) => {
        let fixdList = [...koponsList]

        setLoading(true)
        if (multiSelectStatus != "-1") {

            let theSelects = multiSelect.filter((it) => it.checked)

            if (theSelects != null) {
                theSelects.forEach(async (element) => {
                    let item = fixdList[element.indx]
                    fixdList[element.indx] =
                        await setNewStatus(item._id, multiSelectStatus, newVal)

                })
            }

        }

        props.refreshDisplay();

    }

    const deleteTheSelected = async () => {
        let fixdList = [...koponsList]

        setLoading(true)
        if (multiSelectStatus != "-1") {

            let theSelects = multiSelect.filter((it) => it.checked)

            if (theSelects != null) {
                let theIds = []
                theSelects.forEach((element) => {
                    let item = fixdList[element.indx]
                    theIds.push(item._id)

                })

                await deleteKoponsByIds(theIds)
            }

        }

        props.refreshDisplay();

    }


    const setNewStatus = async (koponId, statBack, newVal) => {
        let fixedItm = await utilsKopons.updateKopon({ koponId, statBack, newVal });
        return fixedItm;
    }

    const deleteKoponsByIds = async (theIds) => {
        let fixedItm = await utilsKopons.deleteKoponsByIds({ theIds });
        return fixedItm;
    }

    const showActivators = (usersUsedIt) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"KoponDispPop"}
                popDiagStyle={"kopActivatorsPop"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={[`מפעילי הקופון (${usersUsedIt.length})`]}
                theInsideComp={<KoponActivatorsList usersUsedIt={usersUsedIt} />}
            />
        )
    }

    const sendToClients = (koponId) => {

        setThePopUp(<InputSet okCLickTxt={"שלח"}
            ContentText={`טלפונים לשליחה? הפרד מספרים עם פסיק...`}
            okClick={(inputTxt) => okSend(inputTxt, koponId)}
            cancelClick={() => { setThePopUp() }}
            errorMsg={"יש להזין לפחות מספר טלפון אחד"} />)

        const okSend = async (inputTxt, koponId) => {
            let phonesList = inputTxt.split(',').map(number => number.trim());
            let sendme = await utilsKopons.sendKoponToClient(koponId, phonesList);
            SucessToast({ position: "toast-bottom-right", text: "נשלח בהצלחה!" });
            setThePopUp()
        }
    }

    return (
        <div className="lstOrderTbls">
            <div className="col-xl-12">
                <div className="table-responsive ms-panel-body">
                    <div className={"fullRow spacePad"}>
                        <MultiLIstKoponSelect multiSelVal={multiSelectStatus}
                            changeToStatus={(statBack) => setMultiSelectStatus(statBack)}
                        />
                        <Button variant="contained" className={"theSBtn"}
                            onClick={() => checkApproveUpdate()}
                            startIcon={<ModeEditIcon style={{ color: 'white' }} />}>
                            בצע
                        </Button>
                    </div>
                    {loading ?
                        <MySpinner_Wait />
                        :
                        <table className="table table-hover thead-primary">
                            <thead>
                                <tr>
                                    <th scope="col" className={"pointing centerText"} onClick={() => { }}>
                                        <Checkbox checked={selectAll} onChange={() => { updateSelAll() }} className="chkBx" />
                                    </th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("Name")}>חבילה/קופון</th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("Type")}>סוג</th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("buissName")}>לעסק</th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("KoponDiscount")}>סכום הקופון</th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("KoponsCount")}>כמות</th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("MinmumForOrder")}>מינ' להפעלה</th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("Used")}>הופעלו</th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("KCode")}>קוד קופון</th>
                                    <th scope="col" className={"centerText pointing"} onClick={() => orderBythis("KStatus")}>פעיל/לא פעיל</th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("KoponExperationDate")}>תאריך תפוגה</th>
                                    <th scope="col" className={"pointing centerText"} onClick={() => orderBythis("KoponCreationDate")}>תאריך יצירה</th>
                                    <th scope="col" className={"pointing centerText"} >מפעילים</th>
                                    <th scope="col" className={"pointing centerText"} >שליחה</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    koponsList?.map((item, i) => {
                                        let colorMe = (new Date(item.KoponExperationDate) < new Date() ||
                                            item.usersUsedIt.length >= item.KoponsCount) ? "redRow" : "";

                                        return (
                                            <tr key={item._id} className={`pointing centerText ${colorMe}`} itmid={item._id}>
                                                <td>
                                                    <Checkbox checked={multiSelect[i]?.checked || false}
                                                        onChange={() => { updateMulti(i) }} className="chkBx" />
                                                </td>
                                                <td onClick={() => rowCellClick(item._id)}>{item.Name}</td>
                                                <td onClick={() => rowCellClick(item._id)}>{item.Type}</td>
                                                <td onClick={() => rowCellClick(item._id)}>{item.buissName}</td>
                                                <td onClick={() => rowCellClick(item._id)}>{item.KoponDiscType === '1' ? `${item.KoponDiscount} ₪` : `${item.KoponDiscount} %`}</td>
                                                <td onClick={() => rowCellClick(item._id)}>{item.KoponsCount}</td>
                                                <td onClick={() => rowCellClick(item._id)}>{item.MinmumForOrder} ₪</td>
                                                <td onClick={() => rowCellClick(item._id)}>{item.Used}</td>
                                                <td onClick={() => rowCellClick(item._id)}>{item.KCode}</td>
                                                <td onClick={() => rowCellClick(item._id)}>{item.KStatus}
                                                    <div className={"bxCheckOnLst"}>
                                                        {item.KStatus ? <p className={"bold inGreen"}>V</p> : <p className={"bold inRed"}>X</p>}
                                                    </div>
                                                </td>
                                                <td onClick={() => rowCellClick(item._id)}>{MyDateTime.getDate(item.KoponExperationDate)}</td>
                                                <td onClick={() => rowCellClick(item._id)}>{MyDateTime.getDate(item.KoponCreationDate)}</td>
                                                <td>
                                                    <Button variant="contained" className={"theSBtn"}
                                                        onClick={() => showActivators(item.usersUsedIt)}
                                                        startIcon={<Visibility style={{ color: 'white' }} />}>
                                                        הצג
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button variant="contained" className={"theSBtn"}
                                                        onClick={() => sendToClients(item._id)}
                                                        startIcon={<Visibility style={{ color: 'white' }} />}>
                                                        SMS
                                                    </Button>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    }
                </div>
            </div>
            {thePopUp}
        </div >
    )
}
export default AllKopons;

