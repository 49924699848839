import { AddBoxOutlined, WifiRounded } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { Dialog } from '@mui/material';

import { Accordion, Card } from 'react-bootstrap';
import ReactDOM from 'react-dom'
import utilsCategurys from '../../../../../MyConns/_Utils/utilsCategurys';
import { MenuContext, RestContext, TheMenuContext } from '../../../../MyContexts/Rest_Context';
import CatsCheckslist from '../../../CategurysP/Categurys-list/CatsCheckslist';
import RichTextComp from '../../../../MySections/TextEditor/RichText';
import InputSet from '../../../../MySections/PopUps/InputSet';
import { HighlightOffOutlined } from '@mui/icons-material';
import PopSelectImage from '../../../../MySections/ImagesSetter/PopSelectImage';
import ConnUrls from '../../../../../MyConns/Connections/ConnectURL';
import InputDubleSet from '../../../../MySections/PopUps/InputDubleSet';
import EraseCheck from '../../../../MySections/PopUps/EraseCheck';
import SelectImgComp from '../../../../MySections/ImagesSetter/SelectImgComp';
import AccordProd from './menuAcoordParts/AccordProd';
import AccordDiv from './menuAcoordParts/AccordDiv';
import AccordSauces from './menuAcoordParts/AccordSauces';
import { UserContext } from '../../../../MyContexts/Users_Context';
import DivSmalltext from './menuAcoordParts/DivSmalltext';
import LaksLst from '../../../../MySections/PopUps/LaksLst';

const TabMenu = (props) => {
    const [rest, setRest] = useContext(RestContext);

    const [user, setUser] = useContext(UserContext);

    const [theMenu, setTheMenu] = useContext(TheMenuContext);


    const [thePopUp, setThePopUp] = useState("");
    const [buisMenu, setBuisMenu] = useState(rest.buisMenu);
    const [dispMenu, setDispMenu] = useState();

    const [saucesAdds, setSaucesAdds] = useState();

    const [chkChopStiks, setChkChopStiks] = useState(rest.chkChopStiks);
    const [chkForkNife, setChkForkNife] = useState(rest.chkForkNife);
    const [chkSauces, setChkSauces] = useState(rest.chkSauces);



    const [mnuItemMainImg, setMnuItemMainImg] = useState(rest.mnuItemMainImg);

    const [diag, setDiag] = useState("");

    useEffect(() => {
        setMeTheMenu();
        setSauces();
    }, [buisMenu, rest])

    useEffect(() => {
    }, [])



    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };


    const setUp = (index) => {
        let temp = array_move([...buisMenu], index, index - 1);
        // rest["buisMenu"] = temp;
        // setBuisMenu(temp);
        UpdateTheBuis(temp);
    }

    const setDown = (index) => {
        let temp = array_move([...buisMenu], index, index + 1);

        UpdateTheBuis(temp);

    }


    const UpdateTheBuis = (tempMnu) => {

        rest.buisMenu = tempMnu;
        theMenu["buisMenu"] = tempMnu;

        setBuisMenu(tempMnu);

    }
    const UpdateTheSaucess = (Sauces) => {

        rest.Sauces = Sauces;

    }
    const UpdateSaucessMakat = (SaucesMakat) => {

        rest.SaucesMakat = SaucesMakat;
        theMenu["SaucesMakat"] = SaucesMakat;

    }

    const removeDivi = (diviIndex) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את המחלקה?'} sucecssFunc={() => removeDivision(diviIndex)} />)
    }

    const removeDivision = (diviIndex) => {

        let tempMnu = [...buisMenu];
        let tempDivi = tempMnu[diviIndex];

        tempMnu.splice(diviIndex, 1);
        UpdateTheBuis(tempMnu);
    }


    const LacksList = () => {
        setThePopUp(
            <LaksLst Title={"רשימת חוסרים (מה שלא מסומן חסר)"}
                fullMenu={buisMenu}
                UpdateTheBuis={(theTemp) => {
                    UpdateTheBuis(theTemp);
                    props.updateTheRest();
                    setThePopUp();
                }}
                cancelClick={() => setThePopUp()}

            />)
    }

    const createNewSection = () => {
        setThePopUp(
            <InputSet Title={"יצירת מחלקה"}
                ContentText={"שם המחלקה"} inLabel={""}
                okClick={(textBack) => createSectionOK(textBack)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור מחלקה חדשה, חובה לתת לה שם."}
            />)
    }




    const createSectionOK = (textBack) => {
        let newSection = { DivName: textBack, Products: [] };
        let tempMnu = [...buisMenu];

        tempMnu.push(newSection);

        UpdateTheBuis(tempMnu);

    }


    const createNewProduct = (divindex) => {
        setThePopUp(
            <InputSet Title={"הוספת מוצר"}
                ContentText={"שם המוצר"} inLabel={""}
                okClick={(textBack) => createProductOK(textBack, divindex)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור מוצר חדש, חובה לתת לו שם."}
            />)
    }

    const createProductOK = (textBack, divindex) => {
        let newProduct = {
            prodName: textBack,
            prodImageUrl: "",
            prodProducts: "",
            prodPrice: 0,
            prodRemarks: "",
            prodProperties: "",
            okToDeliver: true,
            inStock: true,
            prodOptions: []
        };

        let tempMnu = [...buisMenu];
        let TempDivi = tempMnu[divindex];
        TempDivi.Products.push(newProduct);
        tempMnu[divindex] = TempDivi;

        UpdateTheBuis(tempMnu);
    }


    const changeDivName = (divIndex, newValue) => {
        let tempMnu = [...buisMenu];
        tempMnu[divIndex].DivName = newValue;

        UpdateTheBuis(tempMnu);
    }


    const changeChkChopStiks = () => {

        rest["chkChopStiks"] = !chkChopStiks;
        setChkChopStiks(!chkChopStiks)
    }

    const changeChkForkNife = () => {

        setChkForkNife(!chkForkNife)
        rest["chkForkNife"] = !chkForkNife;

    }

    const changeChkSauces = () => {
        setChkSauces(!chkSauces)
        rest["chkSauces"] = !chkSauces;

        if (!chkSauces) { createSaucesSect() }
        else { setSaucesAdds() }
    }



    const createSaucesSect = () => {
        setSaucesAdds(<AccordSauces UpdateTheSaucess={(sauces) => UpdateTheSaucess(sauces)}
            UpdateSaucessMakat={(saucesMakat) => UpdateSaucessMakat(saucesMakat)} />)
    }


    const setSauces = () => {
        if (chkSauces) {
            setSaucesAdds(<AccordSauces UpdateTheSaucess={(sauces) => UpdateTheSaucess(sauces)}
                UpdateSaucessMakat={(saucesMakat) => UpdateSaucessMakat(saucesMakat)}
            />)
        }

    }



    const setDivName = (index, newVal) => {
        let tempoBuisMenu = [...buisMenu]
        tempoBuisMenu[index].DivName = newVal;
        UpdateTheBuis(tempoBuisMenu);

    }

    const setDivDescription = (index, newVal) => {
        let tempoBuisMenu = [...buisMenu]
        tempoBuisMenu[index].DivDescript = newVal;
        UpdateTheBuis(tempoBuisMenu);

    }

    const setMeTheMenu = () => {

        let counter = 0;
        let prodcounter = 0;

        let bk = buisMenu.map((item, index) => {
            counter++;
            return (
                ///מחלקה                
                <Card key={index}>
                    <Accordion.Collapse className="collapseparent" eventKey={counter}>
                        <Card.Body>
                            <Accordion defaultActiveKey="0" className="has-gap ms-accordion-chevron menuSectionsAcord">
                                <div className={"col-md-12 mb-2 onRight"}>
                                    <DivSmalltext prodTxtVal={item.DivName}
                                        clss={"DivSmalltext prodFirst"}
                                        headI={"שם המחלקה"}
                                        setDivDescription={
                                            (newValue) => setDivName(index, newValue)
                                        }

                                    />
                                    <DivSmalltext prodTxtVal={item.DivDescript}
                                        clss={"DivSmalltext prodFirst"}
                                        headI={"טקסט קצר למחלקה"}
                                        setDivDescription={
                                            (newValue) => setDivDescription(index, newValue)
                                        }

                                    />
                                    {user.permiss === "admin" ? <div className={"col-md-2 mb-2 onRight"}>
                                        <Button className={"addDivBtn"} variant="contained" onClick={() => createNewProduct(index)}
                                            startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                            הוסף מוצר
                                        </Button>
                                    </div>
                                        : ""}
                                    {/*                                         
                                    <div className={"col-md-8 mb-2 onRight"}>
                                        <AccordDiv index={index} DivName={item.DivName} changeDivName={(divIndex, newValue) => changeDivName(divIndex, newValue)} />
                                    </div> */}
                                </div>
                                {item.Products.map((produc, proIndex) => {

                                    prodcounter++;
                                    return (
                                        ///מוצר
                                        <AccordProd
                                            key={proIndex}
                                            index={index}
                                            produc={produc}
                                            prodcounter={prodcounter}
                                            proIndex={proIndex}
                                            productsLength={item.Products.length}
                                            UpdateTheBuis={(theTemp) => UpdateTheBuis(theTemp)}
                                        />
                                    )
                                })}
                            </Accordion>
                        </Card.Body>
                    </Accordion.Collapse>
                    <Accordion.Toggle as={Card.Header} eventKey={counter}>
                        <span>{item.DivName}</span><span className={"noBold"}></span>
                    </Accordion.Toggle>
                    {user.permiss === "admin" ?
                        <div className={"acoordBtns"}>
                            <div className={"acoordIcon acord1 pointing"} onClick={() => removeDivi(index)}><i className="far fa-trash-alt theSecIconsColor onLeft" /></div>

                            {index != 0 && rest.buisMenu.length > 1 ? <div className={"acoordIcon acord2 pointing"}
                                onClick={() => setUp(index)} title={"הקפץ למעלה"}><i className="far fa-caret-square-up theSecIconsColor onLeft" />
                            </div> : ""}

                            {index < rest.buisMenu.length - 1 ? <div className={"acoordIcon acord3 pointing"}
                                onClick={() => setDown(index)} title={"הקפץ למטה"}><i className="far fa-caret-square-down theSecIconsColor onLeft" />
                            </div> : ""}
                        </div> : ""}

                </Card >
            )

        })
        setDispMenu(bk)
    }



    return (
        <div className={"menuTab"}>
        
            <div className={"col-md-12 mb-2 onRight"}>
            <div className={"col-md-1 mb-2 onRight fixinMobi"}>
                    <Button className={"rmvDivBtn"} variant="contained" onClick={() => LacksList()}
                        startIcon={<AddBoxOutlined style={{ color: 'white'}} />}>
                        חוסרים
                    </Button>

                </div>
                {user.permiss === "admin" ?
                    <>
                        <div className={"mainMnuimgBlok col-md-2 mb-2 onLeft fixinMobi"}>
                            <label htmlFor={"validationCustom10"} className={"input-group small"}>תמונה ראשונית (472X295)</label>
                            <SelectImgComp imgCss={"medImgDisp pointing"} firstImgToDisp={mnuItemMainImg ? mnuItemMainImg : ""}
                                theFunction={(fUrl) => { setMnuItemMainImg(fUrl); rest["mnuItemMainImg"] = fUrl }} />
                        </div>
                        <div className={"col-md-4 mb-3 onLeft fixinMobi"}>

                            <label className="ms-checkbox-wrap">
                                <input type="checkbox" checked={chkForkNife} onChange={(e) => changeChkForkNife(e.target.value)} /> <i className="ms-checkbox-check" />
                            </label> &nbsp; &nbsp;<span>סכו"ם</span>
                            <br />
                            <label className="ms-checkbox-wrap">
                                <input type="checkbox" checked={chkChopStiks} onChange={(e) => changeChkChopStiks(e.target.value)} /> <i className="ms-checkbox-check" />
                            </label>&nbsp; &nbsp; <span>צ'ופסטיקס</span>
                            <br />
                            <label className="ms-checkbox-wrap">
                                <input type="checkbox" checked={chkSauces} onChange={(e) => changeChkSauces(e.target.value)} /> <i className="ms-checkbox-check" />
                            </label>&nbsp; &nbsp; <span>הצמדת רטבים</span>
                            <br />
                        </div>
                    </>
                    : ""}
                
            </div>



            {user.permiss === "admin" ? <div className={"col-md-12 mb-2 onRight"}>
                <Button className={"addDivBtn"} variant="contained" onClick={() => createNewSection()}
                    startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                    מחלקה
                </Button>
            </div> : ""}

            <Accordion defaultActiveKey="0" className="has-gap ms-accordion-chevron menuSectionsAcord">
                {saucesAdds}
            </Accordion>
            <Accordion defaultActiveKey="0" className="has-gap ms-accordion-chevron menuSectionsAcord">
                {dispMenu}
            </Accordion>
            {diag}
            {thePopUp}
        </div>

    );
}

export default TabMenu;