import React, { Component, useContext, useEffect, useState } from 'react';
import Sidenavigation from '../../layouts/Sidenavigation';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';

import { MenuContextProvider } from '../../MyContexts/Rest_Context';
import ContactP from './ContactP';
import { UserContext } from '../../MyContexts/Users_Context';


const ContactPage = (props) => {
    const [user, setUser] = useContext(UserContext)

    const [thePage, setThePage] = useState();

    useEffect(() => {

        /// myFunctions.connCheck();

        if (props.newp) {
            setThePage(<ContactP newp={true} upContactList={(newitem) => props.upContactList(newitem)} />);
        }
        else {
            setThePage(<ContactP key={props.pageItem._id} pageId={props.pageItem._id}
                upContactList={(newitem) => props.upContactList(newitem)} />);
        }

    }, [props.pageItem])

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">

            <TheMasterContextProvider>
                <Sidenavigation1 />
                <main className="body-content">
                    <MenuContextProvider>
                        <Topnavigation />
                        {user.permiss === "admin" &&
                            { thePage }
                        }
                    </MenuContextProvider>
                </main>
                {/* <Quickbar /> */}
            </TheMasterContextProvider>

        </div>
    );
}

export default ContactPage;