import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Card } from 'react-bootstrap';
import ConnUrls from '../../../../MyConns/Connections/ConnectURL';
import ImgAndTxtCARD from '../../../MySections/DTechComps/ImgAndTxtCARD';
import { HomePContext } from '../../../MyContexts/HomeP_Context';

const TabHPheaders = (props) => {


    const [homeP, setHomeP] = useContext(HomePContext);

    const [mainHead, setMainHead] = useState(homeP.mainHead);
    const [secondHead, setSecondHead] = useState(homeP.secondHead);
    const [TheFavRestsHeader, setTheFavRestsHeader] = useState(homeP.TheFavRestsHeader)
    const [onlineRestsHeader, setOnlineRestsHeader] = useState(homeP.onlineRestsHeader)
    const [PopularsHeader, setPopularsHeader] = useState(homeP.PopularsHeader)
    const [TableOrdersHeader, setTableOrdersHeader] = useState(homeP.TableOrdersHeader)

    useEffect(() => {


    }, [])
    const setTheMainHead = (e) => {
        homeP["mainHead"] = e.target.value;
        setMainHead(e.target.value);
    }


    const setTheSecondHead = (e) => {
        homeP["secondHead"] = e.target.value;
        setSecondHead(e.target.value);
    }




    return (
        <>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>כותרת ראשית</label>
                <span className={"explanation mediumst fullROw"}></span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_17"} placeholder={"כותרת"} value={mainHead}
                        onChange={(e) => { setTheMainHead(e) }}
                    />

                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>כותרת משנית</label>
                <span className={"explanation mediumst fullROw"}></span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_17"} placeholder={"כותרת"} value={secondHead}
                        onChange={(e) => { setTheSecondHead(e) }}
                    />

                </div>
            </div>

            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>כותרת מקטעים ראשונה</label>
                <span className={"explanation mediumst fullROw"}></span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_17"} placeholder={"אוןליין"} value={onlineRestsHeader}
                        onChange={(e) => { setOnlineRestsHeader(e.target.value); homeP["onlineRestsHeader"] = e.target.value; }}
                    />

                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>כותרת מקטעים שניה</label>

                <span className={"explanation mediumst fullROw"}></span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_17"} placeholder={"אהובות"} value={TheFavRestsHeader}
                        onChange={(e) => { setTheFavRestsHeader(e.target.value); homeP["TheFavRestsHeader"] = e.target.value; }}
                    />

                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>כותרת מקטעים שלישית</label>

                <span className={"explanation mediumst fullROw"}></span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_17"} placeholder={"הזמנת שולחן"} value={TableOrdersHeader}
                        onChange={(e) => { setTableOrdersHeader(e.target.value); homeP["TableOrdersHeader"] = e.target.value; }}
                    />

                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>כותרת מקטעים רביעית</label>
                <span className={"explanation mediumst fullROw"}></span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_17"} placeholder={"פופולריות"} value={PopularsHeader}
                        onChange={(e) => { setPopularsHeader(e.target.value); homeP["PopularsHeader"] = e.target.value; }}
                    />

                </div>
            </div>

        </>

    );
}

export default TabHPheaders;