import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
import utilsHomePage from '../../../MyConns/_Utils/utilsHomePage';
import Breadcrumb from './Breadcrumb'
// import TabBuisDetails from './parts/TabBuisDetails';
// import TabImages from './parts/TabImages';
// import TabMaps from './parts/TabMaps';
// import TabNetwork from './parts/TabNetwork';
// import TabSales from './parts/TabSales';
// import TabPageDetails from './parts/TabPageDetails';
// import TabOpeningHours from './parts/TabOpeningHours';

import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import { HomePContext } from '../../MyContexts/HomeP_Context';

import { useHistory } from "react-router-dom";
import BasicPopUp from '../../MySections/PopUps/BasicPopUp';
import SucessToast from '../../MySections/Toasts/SucessToast';
import TabPageDetails from '../compsForAllPages/TabPageDetails';
import TabHPDetails from './parts/TabHPDetails';
import TabMainRests from './parts/TabMainRests';
import TabFavoRests from './parts/TabFavoRests';
import TabHPheaders from './parts/TabHPheaders';
import myFunctions from '../../../myFunctions';
import TabPopularRests from './parts/TabPopularRests';
import TabOnlineRests from './parts/TabOnlineRests';



const HomePSet = (props) => {
    const history = useHistory();

    const [homeP, setHomeP] = useContext(HomePContext);
    const [loading, setLoading] = useState(true);
    let idParam = useParams();
    const [thePopUp, setThePopUp] = useState("");
    const [pg, setPg] = useState("");

    useEffect(() => {
        getHomeP();
        setPg(myFunctions.setGetCurrnetTab("Get", "pg"));
    }, [])

    const tempUpdate = (upRest) => {
        setHomeP(upRest);
    }

    const getHomeP = async () => {

        let HomePLst = await utilsHomePage.getHomePage();
        setHomeP(HomePLst);
        setLoading(false);
    }


    const updateTheHomeP = async () => {
        let HomePLst = await utilsHomePage.updateHomePage(homeP);
        // setRest(rstLst);
        setHomeP({ ...homeP })
        SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
    }



    return (
        <div className="ms-content-wrapper SetBlock">
            <Breadcrumb />
            {/* Active Orders Graph */}
            <div className="row">
                <div className="col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-header">
                            <div><h6>הגדרות עמוד הבית</h6></div>
                            <div className={"buisTollBar"}>


                            </div>
                        </div>
                        {loading ? "loading" :
                            <div className="col-md-12">

                                <div className="ms-panel-body clearfix">
                                    <Tab.Container defaultActiveKey={pg}>
                                        <Nav variant="tabs" className="nav nav-tabs d-flex nav-justified mb-2">
                                            <Nav.Item>


                                                <Nav.Link eventKey="tab5_HP"
                                                    onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "tab5_HP")}>כותרות</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab2_HP"
                                                    onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "tab2_HP")}>סליידרים</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab3_HP"
                                                    onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "tab3_HP")}>מקטעים ראשונה</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab7_HP"
                                                    onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "tab7_HP")}>מקטעים שנייה</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab4_HP"
                                                    onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "tab4_HP")}>מקטעים שלישית</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab6_HP"
                                                    onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "tab6_HP")}>מקטעים רביעית</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab1_HP"
                                                    onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "tab1_HP")}>פרטי העמוד</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            {/*כותרות*/}
                                            <Tab.Pane eventKey="tab5_HP">
                                                <div className="form-row tabsInputs ">
                                                    <TabHPheaders />
                                                </div>
                                            </Tab.Pane>
                                            {/*סליידרים*/}
                                            <Tab.Pane eventKey="tab2_HP">
                                                <div className="form-row tabsInputs ">
                                                    <TabHPDetails />
                                                </div>
                                            </Tab.Pane>
                                            {/* עסקים אונליין */}
                                            <Tab.Pane eventKey="tab7_HP">
                                                <div className="form-row tabsInputs ">
                                                    <TabOnlineRests />
                                                </div>
                                            </Tab.Pane>
                                            {/* עסקים מקודמים */}
                                            <Tab.Pane eventKey="tab3_HP">
                                                <div className="form-row tabsInputs ">
                                                    <TabMainRests />
                                                </div>
                                            </Tab.Pane>
                                            {/* האהובים ביותר */}
                                            <Tab.Pane eventKey="tab4_HP">
                                                <div className="form-row tabsInputs ">
                                                    <TabFavoRests />
                                                </div>
                                            </Tab.Pane>
                                            {/*מנות פופולאריות*/}
                                            <Tab.Pane eventKey="tab6_HP">
                                                <div className="form-row tabsInputs ">
                                                    <TabPopularRests />
                                                </div>
                                            </Tab.Pane>

                                            {/* פרטי העמוד */}
                                            <Tab.Pane eventKey="tab1_HP">
                                                <div className="form-row tabsInputs ">
                                                    <TabPageDetails thePage={homeP} thePageUpdate={(key, val) => { homeP[key] = val }} />
                                                </div>
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </Tab.Container>
                                </div>



                            </div>}
                    </div>
                </div>
            </div>
            <div className="ms-panel-header approveLine">
                <button type="button" className="onLeft btn btn-primary" onClick={() => updateTheHomeP()}>שמור</button>
            </div>
            {thePopUp}
        </div >
    );
}


export default HomePSet;