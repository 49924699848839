import React, { Component, useContext, useEffect, useState } from 'react';
import ReportCalcs from './ReportCalcs';




const TotalsBarObjects = (props) => {
    const [thePrms, setThPrms] = useState(props.prms);


    useEffect(() => {
    }, [])


    return (
        <div className="col-md-12">
            <div className="col-md-4 onRight printright">
                <h5 className="bold no_print">ללא ביטולים</h5>

                <div className={"totalReportsLine"}>
                    <div className={"onRight spaceMargeLeftbig"}>משלוחים: {thePrms.orderlisttable.filter(or => or.ord.OrderType === "Deliver" &&
                        or.ord.orderStatus !== "מבוטל").length}</div>
                    <div className={"onRight"}>סכום: {thePrms.totOfDelivery.totClear} ₪</div>
                </div>
                <div className={"totalReportsLine"}>
                    <div className={"onRight spaceMargeLeftbig"}>איסופים: {thePrms.orderlisttable.filter(or => or.ord.OrderType != "Deliver" &&
                        or.ord.orderStatus !== "מבוטל").length}</div>
                    <div className={"onRight"}>סכום: {thePrms.totOfTakaway.totClear} ₪</div>
                </div>
                <div className={"totalReportsLine"}>
                    <div className={"onRight spaceMargeLeftbig"}>מזומן: {thePrms.orderlisttable.filter(or => or.ord.payt === "2" &&
                        or.ord.orderStatus !== "מבוטל").length}</div>
                    <div className={"onRight"}>סכום: {thePrms.totOfCash.totClear} ₪</div>
                </div>
                <div className={"totalReportsLine"}>
                    <div className={"onRight spaceMargeLeftbig"}>אשראי/ביט: {thePrms.orderlisttable.filter(or => or.ord.payt != "2" &&
                        or.ord.orderStatus !== "מבוטל").length}</div>
                    <div className={"onRight"}>סכום: {thePrms.totOfCreditcard.totClear} ₪</div>
                </div>
                <div className={"totalReportsLine"}>
                    <div className={"onRight spaceMargeLeftbig"}>סה"כ הזמנות: {thePrms.orderlisttable.filter(or => or.ord.orderStatus !== "מבוטל").length}</div>
                    <div className={"onRight"}>סה"כ: {thePrms.totOfAll.totClear} ₪</div>
                </div>

            </div>
            <div className="col-md-4 onRight no_print">
                <h5 className="bold">ביטולים</h5>

                <div className={"totalReportsLine"}>
                    <div className={"onRight spaceMargeLeftbig"}>משלוחים: {thePrms.orderlisttable.filter(or => or.ord.OrderType === "Deliver" &&
                        or.ord.orderStatus === "מבוטל").length}</div>
                    <div className={"onRight"}>סכום: {thePrms.totOfDelivery.totCancels} ₪</div>
                </div>
                <div className={"totalReportsLine"}>
                    <div className={"onRight spaceMargeLeftbig"}>איסופים: {thePrms.orderlisttable.filter(or => or.ord.OrderType != "Deliver" &&
                        or.ord.orderStatus === "מבוטל").length}</div>
                    <div className={"onRight"}>סכום: {thePrms.totOfTakaway.totCancels} ₪</div>
                </div>
                <div className={"totalReportsLine"}>
                    <div className={"onRight spaceMargeLeftbig"}>מזומן: {thePrms.orderlisttable.filter(or => or.ord.payt === "2" &&
                        or.ord.orderStatus === "מבוטל").length}</div>
                    <div className={"onRight"}>סכום: {thePrms.totOfCash.totCancels} ₪</div>
                </div>
                <div className={"totalReportsLine"}>
                    <div className={"onRight spaceMargeLeftbig"}>אשראי/ביט: {thePrms.orderlisttable.filter(or => or.ord.payt != "2" &&
                        or.ord.orderStatus === "מבוטל").length}</div>
                    <div className={"onRight"}>סכום: {thePrms.totOfCreditcard.totCancels} ₪</div>
                </div>
                <div className={"totalReportsLine"}>

                    <div className={"totalReportsLine"}>
                        <div className={"onRight spaceMargeLeftbig"}>
                            <div className={"onRight spaceMargeLeftbig"}>סה"כ הזמנות מבוטלות: {thePrms.orderlisttable.filter(or => or.ord.orderStatus === "מבוטל").length}</div>
                            <div className={"onRight"}> סכום הביטולים סה"כ: {thePrms.totOfAll.totCancels} ₪</div>
                        </div>
                    </div>


                </div>

            </div>
            <div className="col-md-4 printright onRight no_print">
                <h5 className="bold">העברות</h5>
                <ReportCalcs totals={{
                    totOfAll: thePrms.totOfAll,
                    totOfCreditcard: thePrms.totOfCreditcard,
                    totOfCash: thePrms.totOfCash, userBuis: thePrms.userBuis
                }} />
            </div>
        </div>
    );
}

export default TotalsBarObjects;
