import contactP from "../_ApiConn/apiContactPage";


const getContactPageById = async (idToGet) => {
    let dFromF = await contactP.getContactPageById(idToGet);
    return (dFromF.data);
}

const allPgsNames = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await contactP.allPgsNames(token);
    return (dFromF.data);
}




const deleteContactPage = async (id, obj) => {
    let dFromF = await contactP.deleteContactPage(id, obj);
    return (dFromF.data);
}


const updatecontactP = async (currSingle) => {

    if (currSingle._id) {
        let dFromF = await contactP.updateSingle(currSingle._id, currSingle);
        return (dFromF.data);
    }

}

const createContactPage = async () => {
    let dFromF = await contactP.createContactPage();
    return (dFromF.data);
}

const getPageByName = async (name) => {
    let dFromF = await contactP.getPageByName(name, { obj: "" });
    return (dFromF.data[0]);
}

export default {
    getContactPageById,
    updatecontactP,
    getPageByName,
    createContactPage,
    allPgsNames,
    deleteContactPage
    
};