import React, { useContext, useEffect, useState } from 'react'
import { RestContext } from "../../../../../MyContexts/Rest_Context";
import Button from '@mui/material/Button';
import { Accordion, Card } from 'react-bootstrap';
import EraseCheck from '../../../../../MySections/PopUps/EraseCheck';
import { AddBoxOutlined, HighlightOffOutlined } from '@mui/icons-material';
import InputDubleSet from '../../../../../MySections/PopUps/InputDubleSet';
import AccordProdOptions from './AccordProdOptions';
import InputSet from '../../../../../MySections/PopUps/InputSet';
import SelectImgComp from '../../../../../MySections/ImagesSetter/SelectImgComp';
import { Dialog } from '@mui/material';
import SelectFromSilo from '../../../../../MySections/DTechComps/SelectFromSilo';
import AccordProdTextInput from './AccordProdTextInput'
import AccordProdNumInput from './AccordProdNumInput'
import AccordProdTextArea from './AccordProdTextArea';
import utilsRests from '../../../../../../MyConns/_Utils/utilsRests';
import AccordProdChkSaucess from './AccordProdChkSaucess';
import { UserContext } from '../../../../../MyContexts/Users_Context';
import AcoordBtns from './AcoordBtns';
import { ScreenWidthContext } from '../../../../../MyContexts/ScreenWidth_Context';

const AccordProd = (props) => {

    const [user, setUser] = useContext(UserContext);
    const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);

    const [rest] = useContext(RestContext);
    const [buisMenu, setbuisMenu] = useState(rest.buisMenu);
    const [thePopUp, setThePopUp] = useState("");
    const [diag, setDiag] = useState("");
    const [chkSauces, setChkSauces] = useState(rest.chkSauces);

    let index = props.index;
    let proIndex = props.proIndex;
    let prodcounter = props.prodcounter;
    let optis = "";
    let addsCounter = 0;


    useEffect(() => {

        setbuisMenu(rest.buisMenu);
    }, [rest.buisMenu])



    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };


    const changeProdName = (divIndex, prodIndex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodName = newValue;

        props.UpdateTheBuis(tempMnu);
    }

    const changeCashRegMakat = (divIndex, prodIndex, newValue) => {


        let tempMnu = [...buisMenu];
        let theItt = { ...tempMnu[divIndex].Products[prodIndex], cashRegMakat: newValue };
        tempMnu[divIndex].Products[prodIndex] = theItt;

        props.UpdateTheBuis(tempMnu);
    }

    
    const changeProdPrice = (divIndex, prodIndex, newValue) => {
        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodPrice = newValue;

        props.UpdateTheBuis(tempMnu);
    }
    const changeProdRemarks = (divIndex, prodIndex, newValue) => {
        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodRemarks = newValue;

        props.UpdateTheBuis(tempMnu);
    }
    const changeProdProducts = (divIndex, prodIndex, newValue) => {
        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodProducts = newValue;

        props.UpdateTheBuis(tempMnu);
    }

    const changeProdProperties = (divIndex, prodIndex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodProperties = newValue;

        props.UpdateTheBuis(tempMnu);
    }




    const createNewOption = (divindex, prodIndex) => {
        setThePopUp(
            <InputSet Title={"הוספת נלווה"}
                ContentText={"שם נלווה"} inLabel={""}
                okClick={(textBack) => createOptionOK(textBack, divindex, prodIndex)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור נלווה חדש, חובה לתת לו שם."}
            />)
    }

    const createOptionOK = (textBack, divindex, prodIndex) => {

        let newOption = {
            optionName: textBack,
            optionBtnTxt: "",
            allowMulti: false,
            selected: 0,
            mustSelect: false,
            options: []
        };

        let tempMnu = [...buisMenu];
        let proItm = tempMnu[divindex].Products[prodIndex];
        proItm.prodOptions.push(newOption);
        tempMnu[divindex].Products[prodIndex] = proItm;

        props.UpdateTheBuis(tempMnu);
    }




    const getDetOpFromSilo = (divindex, prodIndex) => {
        setDiag(
            <Dialog onClose={() => setDiag()} open={true} className={"popUpWrapMedia"}>
                <div className={"inDiag"}>
                    <HighlightOffOutlined sx={{ fontSize: 40 }}
                        className={"pointing closeDiagBtn"} onClick={() => setDiag()} />
                    <SelectFromSilo setTheList={(theSilo) => { createMultiOptions(theSilo, divindex, prodIndex); setDiag() }} handleClose={() => setDiag()} />
                </div>
            </Dialog>
        )
    }

    const createMultiOptions = (theSilo, divindex, prodIndex) => {

        let allFromSilo = theSilo.map((item, index) => {
            return (
                {
                    optionName: item.optionName,
                    optionBtnTxt: item.optionBtnTxt,
                    allowMulti: item.allowMulti,
                    selected: item.selected,
                    mustSelect: item.mustSelect,
                    options: item.options
                }
            )
        })

        let tempMnu = [...buisMenu];
        let proItm = tempMnu[divindex].Products[prodIndex];
        allFromSilo.forEach(element => {
            proItm.prodOptions.push(element);
        });

        tempMnu[divindex].Products[prodIndex] = proItm;

        props.UpdateTheBuis(tempMnu);
    }


    const setProdMainImg = (fUrl, diviIndex, proIndex) => {
        let tempMnu = [...buisMenu];
        tempMnu[diviIndex].Products[proIndex].prodImageUrl = fUrl;

        props.UpdateTheBuis(tempMnu);
    }

    const removeProd = (diviIndex, prodIndex) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את המוצר?'} sucecssFunc={() => removeProduct(diviIndex, prodIndex)} />)
    }

    const removeProduct = (diviIndex, prodIndex) => {
        let tempMnu = [...buisMenu];
        let tempDivi = tempMnu[diviIndex];
        tempDivi.Products.splice(prodIndex, 1);
        tempMnu[diviIndex] = tempDivi;

        props.UpdateTheBuis(tempMnu);
    }


    const setUpProd = (index, proIndex) => {

        let tempMnu = [...buisMenu];

        let temp = array_move([...tempMnu[index].Products], proIndex, proIndex - 1);

        tempMnu[index].Products = temp;

        props.UpdateTheBuis(tempMnu);
    }

    const setDownProd = (index, proIndex) => {

        let tempMnu = [...buisMenu];

        let temp = array_move([...tempMnu[index].Products], proIndex, proIndex + 1);

        tempMnu[index].Products = temp;

        props.UpdateTheBuis(tempMnu);
    }

    const setInStock = (index, proIndex) => {

        let tempMnu = [...buisMenu];

        tempMnu[index].Products[proIndex].inStock = !tempMnu[index].Products[proIndex].inStock;

        props.UpdateTheBuis(tempMnu);

    }

    const setOkToDeliver = (index, proIndex) => {

        let tempMnu = [...buisMenu];

        tempMnu[index].Products[proIndex].okToDeliver = !tempMnu[index].Products[proIndex].okToDeliver;

        props.UpdateTheBuis(tempMnu);


    }


    const changeProdChkSauces = (divIndex, prodIndex, ProdSauces, ValSauces) => {

        let tempMnu = [...buisMenu];

        tempMnu[divIndex].Products[prodIndex].chkProdSauces = ProdSauces;
        tempMnu[divIndex].Products[prodIndex].chkProdValSauces = ValSauces;

        props.UpdateTheBuis(tempMnu);

    }

    const changeProdChkValSauces = (divIndex, prodIndex, newValue) => {

        let tempMnu = [...buisMenu];

        tempMnu[divIndex].Products[prodIndex].chkProdValSauces = newValue;

        props.UpdateTheBuis(tempMnu);
    }


    return (
        <Card key={proIndex}>
            {user.permiss === "admin" ?
                <Accordion.Collapse className="collapseparent" eventKey={prodcounter}>
                    <Card.Body>
                        <div key={proIndex} className={"prod"}>
                            <div className={"col-md-12 fullRow"}>

                                <AccordProdTextInput clss={"col-md-9 prodFirst onRight theFrow "} headI={"שם המוצר"} index={index} proIndex={proIndex} prodTxtVal={props.produc.prodName}
                                    setProdItm={(index, proIndex, newValue) => changeProdName(index, proIndex, newValue)} />
                                <AccordProdNumInput clss={"col-md-9 prodFirst onRight theFrow "} headI={"מחיר"} index={index} proIndex={proIndex} prodTxtVal={props.produc.prodPrice}
                                    setProdItm={(index, proIndex, newValue) => changeProdPrice(index, proIndex, newValue)} />
                                <div className={"prodFirst col-md-2 onLeft"}>
                                    <label>472X295</label>
                                    <SelectImgComp imgCss={"medImgDisp pointing"} firstImgToDisp={props.produc.prodImageUrl ? props.produc.prodImageUrl : ""}
                                        theFunction={(fUrl) => { setProdMainImg(fUrl, index, proIndex) }} />
                                </div>

                                <AccordProdTextArea clss={"col-md-9 prodFirst onRight theFrow "} headI={"פירוט המנה"} index={index} proIndex={proIndex} prodTxtVal={props.produc.prodProducts}
                                    setProdItm={(index, proIndex, newValue) => changeProdProducts(index, proIndex, newValue)} />
                                <AccordProdTextInput clss={"col-md-9 prodFirst onRight theFrow "} headI={"הערות"} index={index} proIndex={proIndex} prodTxtVal={props.produc.prodRemarks}
                                    setProdItm={(index, proIndex, newValue) => changeProdRemarks(index, proIndex, newValue)} />

                                <AccordProdTextInput clss={"col-md-9 prodFirst onRight theFrow "} headI={"מאפיינים נוספים"} index={index} proIndex={proIndex} prodTxtVal={props.produc.prodProperties}
                                    setProdItm={(index, proIndex, newValue) => changeProdProperties(index, proIndex, newValue)} />
                                <AccordProdTextInput clss={"col-md-9 prodFirst onRight theFrow "} headI={"מקט לקופה"} index={index} proIndex={proIndex} prodTxtVal={props.produc.cashRegMakat}
                                    setProdItm={(index, proIndex, newValue) => changeCashRegMakat(index, proIndex, newValue)} />

                                {/* הצמדת רטבים */

                                    chkSauces ? <AccordProdChkSaucess
                                        clss={""}
                                        headI={"הצמדת רטבים"}
                                        index={index} proIndex={proIndex}
                                        prodTxtVal={props.produc.chkProdSauces}
                                        prod_chkProdSauces={props.produc.chkProdSauces}
                                        prod_chkProdValSaucess={props.produc.chkProdValSauces}
                                        setProdItm={(ProdSauces, ValSauces) => changeProdChkSauces(index, proIndex, ProdSauces, ValSauces)}
                                        setProdItm2={(newValue) => changeProdChkValSauces(index, proIndex, newValue)}
                                    />
                                        : ""
                                }



                            </div>

                            {user.permiss === "admin" ?
                                <div className={"prodOptsWrap"}>
                                    <Accordion defaultActiveKey="0" className="has-gap ms-accordion-chevron menuSectionsAcord">
                                        <Card>
                                            <Accordion.Collapse className="collapseparent" eventKey={`Pr${prodcounter}`}>
                                                <Card.Body>
                                                    <Accordion defaultActiveKey="0" className="has-gap ms-accordion-chevron menuSectionsAcord">
                                                        <Button variant="contained" className={"addDivBtn"} onClick={() => createNewOption(index, proIndex)}
                                                            startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                                            הוסף נלווה
                                                        </Button>
                                                        &nbsp;
                                                        <Button variant="contained" className={"addDivBtn"} onClick={() => getDetOpFromSilo(index, proIndex)}
                                                            startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                                            נלווה ממאגר
                                                        </Button>
                                                        <br />  <br />
                                                        {
                                                            props.produc.prodOptions?.map((prodOpt, OptINdex) => {
                                                                addsCounter++;
                                                                optis = (optis === "") ? prodOpt.optionName : optis + " , " + prodOpt.optionName
                                                                return (
                                                                    ///נלווים
                                                                    <AccordProdOptions key={OptINdex}
                                                                        prodOpt={prodOpt} index={index}
                                                                        proIndex={proIndex}
                                                                        OptINdex={OptINdex}
                                                                        addsCounter={addsCounter}
                                                                        prodsOptLength={props.produc.prodOptions.length}
                                                                        UpdateTheBuis={(theTemp) => props.UpdateTheBuis(theTemp)} />
                                                                )
                                                            })
                                                        }
                                                    </Accordion>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                            <Accordion.Toggle as={Card.Header} eventKey={`Pr${prodcounter}`}>
                                                <span>נלווים</span><span className={"noBoldvSmall"}> {optis}</span>
                                            </Accordion.Toggle>
                                        </Card>
                                    </Accordion>
                                </div>
                                : ""}
                        </div>
                    </Card.Body>
                </Accordion.Collapse >
                : ""}
            <Accordion.Toggle as={Card.Header} eventKey={prodcounter}>
                {(screenWidth <= 1024) ?
                    <AcoordBtns
                        cs={"acoordBtnsMobi"}
                        user={user}
                        index={index}
                        proIndex={proIndex}
                        produc={props.produc}
                        productsLength={props.productsLength}
                        removeProd={(index, proIndex) => removeProd(index, proIndex)}
                        setUpProd={(index, proIndex) => setUpProd(index, proIndex)}
                        setDownProd={(index, proIndex) => setDownProd(index, proIndex)}
                        setInStock={(index, proIndex) => setInStock(index, proIndex)}
                        setOkToDeliver={(index, proIndex) => setOkToDeliver(index, proIndex)}

                    />
                    : ""}
                <span className={"fullRow"}><span className={"onRight"}>
                    {props.produc.prodName} &nbsp;</span> <span className={"onRight"}> {props.produc.prodPrice} ₪</span></span><span className={"noBoldvSmall"}>{optis}</span>
                {(screenWidth > 1024) ?
                    <AcoordBtns
                        cs={"acoordBtns"}
                        user={user}
                        index={index}
                        proIndex={proIndex}
                        produc={props.produc}
                        productsLength={props.productsLength}
                        removeProd={(index, proIndex) => removeProd(index, proIndex)}
                        setUpProd={(index, proIndex) => setUpProd(index, proIndex)}
                        setDownProd={(index, proIndex) => setDownProd(index, proIndex)}
                        setInStock={(index, proIndex) => setInStock(index, proIndex)}
                        setOkToDeliver={(index, proIndex) => setOkToDeliver(index, proIndex)}

                    />
                    : ""}
            </Accordion.Toggle>


            {thePopUp}
            {diag}
        </Card >
    )
}

export default AccordProd;